import { PrivateMainLayout } from "../../components/layout";
import {
  LoadingContent,
  PatientDashboard,
  ProfessionalDashboard,
} from "./components";
import { useSessionContext } from "../../contexts/SessionContext";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/material";
import { MobileBarNavigation } from "../../components/layout/private/main/MobileBarNavigation";
import { useEffect, useState } from "react";
import { GetSchedule } from "../../services/fhir";
import { oids } from "../../configs/Settings";

export function Home() {
  const { access, loading, user } = useSessionContext();
  const [loadingVerify, setLoadingVerify] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>();

  useEffect(() => {
    async function fectchSchedules() {
      setLoadingVerify(true);

      try {
        const auxActor =
          !access?.roles?.includes("manager") &&
          `Practitioner/${oids.cpf}-${user.username}`;

        const scheduleResponse = await GetSchedule({
          // active: true,
          ...(auxActor && { actor: auxActor }),
          organization: `${oids.cnpj}-${access.organizationId}`,
        });
        setSchedule(scheduleResponse?.entry);
      } catch (err) {
        console.log("err");
      } finally {
        setLoadingVerify(false);
      }
    }

    if (access?.organizationId) {
      fectchSchedules();
    }
  }, []);
  return (
    <>
      <PrivateMainLayout dashboard noSidebar={isMobile} mobileView={isMobile}>
        {!loading && access.type === "patient" && <PatientDashboard />}
        {!loading && access.type === "professional" && (
          <ProfessionalDashboard schedule={schedule} />
        )}

        {(access.type === undefined || access.type === null || loading) && (
          <LoadingContent />
        )}
      </PrivateMainLayout>
      <Stack width="100%" sx={{ display: { xs: "block", lg: "none" } }} mt={5}>
        <MobileBarNavigation />
      </Stack>
    </>
  );
}
