import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import {
  CircularLoading,
  DisplayTextField,
} from "../../../../components/common";
import {
  FormButtonGroup,
  FormDateInput,
  FormSelectInput,
  FormTextInput,
} from "../../../../components/form";
import { useRegex } from "../../../../utils/useRegex";
import { useFormatter } from "../../../../utils/useFormatter";
import { IPatientData } from "../../../../utils/interfaces";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { GetPatient, PostPatient } from "../../../../services/fhir";
import { toast } from "react-toastify";
import { Steps } from "../../types";
import { useWatch } from "react-hook-form";
import { oids } from "../../../../configs/Settings";
import { useFhirData } from "../../../../utils/useFhirData";
import { SearchCPF } from "../../../../services/scim";
import { GetRelatedPerson } from "../../../../services/fhir/relatedperson/GetRelatedPerson";

interface IProps {
  control: any;
  setValue: any;
  navigateToNextPage: any;
  handleSubmit: any;
  setPage: any;
  cpf: any;
  row: any;
  walkin: any;
  type: "patient" | "responsible";
  reset: any;
  dataResponsible?: any;
  setDataResponsible?: any;
  watchAllFields?: any;
}

const typeOptionsGender: Array<any> = [
  { display: "Masculino", value: "male" },
  { display: "Feminino", value: "female" },
];

export function CreatePatient({
  cpf,
  control,
  setValue,
  navigateToNextPage,
  handleSubmit,
  setPage,
  row,
  walkin,
  type,
  reset,
  dataResponsible,
  setDataResponsible,
  watchAllFields,
}: IProps) {
  const { organization } = useOrganizationContext();
  const [serproResponse, setSerproResponse] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [minorPatient, setMinorPatient] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState(false);

  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const hasRunRef = useRef(false);

  let selectedPatient = useWatch({ name: "patientActor", control });

  const searchPatient =
    selectedPatient?.reference?.split("/")?.[1] !==
    `${oids.cpf}-${cpf?.replace(/[.-]/g, "")}`;

  function isMinor(birthDate: any) {
    const today = dayjs();
    const birth = dayjs(birthDate);
    const age = today.diff(birth, "year");

    const hasHadBirthdayThisYear = today.isAfter(birth.add(age, "year"));

    return age < 18 || (age === 18 && !hasHadBirthdayThisYear);
  }

  const patientExitsInRelated = async (
    patientId: string,
    linkResponsible: any
  ) => {
    const responseRelated = await GetRelatedPerson({
      patient: patientId,
    });

    const extractResourceIds = (resources: any[]): Set<string> => {
      return new Set(resources?.map((resource) => resource?.resource?.id));
    };

    const extractReferenceIds = (references: any[]): Set<string> => {
      return new Set(
        references?.map((ref) => ref?.other?.reference?.split("/")?.[1])
      );
    };

    const findMatches = (
      resourceIds: Set<string>,
      referenceIds: Set<string>
    ): string[] => {
      return Array.from(referenceIds).filter((id) => resourceIds?.has(id));
    };

    const resourceIds = extractResourceIds(responseRelated?.data?.entry);
    const referenceIds = extractReferenceIds(linkResponsible);

    const matches = findMatches(resourceIds, referenceIds);

    const verifyStatusInactive = responseRelated?.data?.entry?.find(
      (el: any) => el.resource.id === matches?.[0]
    );

    if (
      !verifyStatusInactive ||
      verifyStatusInactive?.resource?.active === false
    ) {
      setValue("statusRelated", verifyStatusInactive);
      setPage(Steps.S4ConfirmRelationship);
    } else {
      if (walkin) setPage(Steps.S5SelectAppointmentActors);
      else setPage(Steps.S6ConfirmAppointment);
    }
  };

  useEffect(() => {
    let shouldRun = !serproResponse && searchPatient;

    if (!shouldRun || hasRunRef.current) return;

    hasRunRef.current = true;

    setLoading(true);
    const getDataPatient = async () => {
      try {
        if (!shouldRun) return;

        if (searchPatient) {
          const patient = await GetPatient({
            identifier: cpf?.replace(/[.-]/g, ""),
          });
          if (!patient?.data) {
            throw new Error();
          } else {
            if (type === "responsible") {
              setDataResponsible(patient?.data);
            } else {
              if (isMinor(patient?.data?.birthDate) && !dataResponsible) {
                setPage(Steps.S1SelectPatient);
                toast.warn(
                  "O paciente é menor de idade, por favor informe um responsável"
                );
                return;
              }
              const patientName = patient?.data?.name?.[0]?.text || "";
              const auxObjReference = {
                relationship: watchAllFields?.relationship,
                ownerName: dataResponsible?.name?.[0]?.text,
                phoneNumber: dataResponsible?.telecom?.[0]?.value,
                gender: patient?.data?.gender,
              };

              const auxMpiResponse = {
                data: {
                  id: patient?.data?.id,
                  name: [
                    {
                      text: patientName,
                    },
                  ],
                },
              };
              setValue("patientActor", {
                reference: `Patient/${patient?.data?.id}`,
                display: patient?.data?.name?.[0]?.text || "",
              });
              setValue("auxObjReference", auxObjReference);
              setValue("auxMpiResponse", auxMpiResponse);

              if (dataResponsible) {
                await patientExitsInRelated(
                  patient?.data?.id,
                  dataResponsible?.link
                );
                return;
              } else {
                if (walkin) setPage(Steps.S5SelectAppointmentActors);
                else setPage(Steps.S6ConfirmAppointment);
              }
            }
          }

          toast.success(
            `${type === "patient" ? "Paciente" : "Responsável"} encontrado`
          );
          if (type === "responsible") {
            setPage(Steps.S3CreatePatient);
          }
        }
      } catch (err) {
        const serproSearch = await SearchCPF(cpf.replace(/[.-]/g, ""));
        if (serproSearch?.status === 422) {
          if (!dataResponsible) {
            setPage(Steps.S1SelectPatient);
            toast.warn(
              "O paciente é menor de idade, por favor, adicione um responsável!"
            );
            return;
          }
          setMinorPatient(true);
        }

        if (serproSearch?.status !== 400) {
          toast.warn(
            `É necessário realizar o cadastro do ${
              type === "patient" ? "paciente" : "responsável"
            }`
          );
          setSerproResponse(serproSearch?.data);
        } else {
          toast.warn(
            `${
              type === "patient" ? "Paciente" : "Responsável"
            } não encontrado, por favor verifique os dados e tente novamente!`
          );
          reset();
          setPage(Steps.S1SelectPatient);
        }
      } finally {
        setLoading(false);
      }
    };

    if (!serproResponse) {
      getDataPatient();
    }
  }, [serproResponse, searchPatient, cpf, type, setValue, setPage]);

  const createPatientMPI = async (data: IPatientData) => {
    setLoading(true);
    const body = {
      name: serproResponse?.nome || data?.name,
      identifier: serproResponse?.ni || data?.cpf.replace(/[.-]/g, ""),
      birthDate: dayjs(serproResponse?.nascimento, "DDMMYYYY").isValid()
        ? dayjs(serproResponse?.nascimento, "DDMMYYYY").format("YYYY-MM-DD")
        : dayjs(data?.birthDate).format("YYYY-MM-DD"),
      phoneNumber: data?.phoneNumber.replace(/\D/g, ""),
      managingOrganization: organization?.id,
      gender: data.gender,
    };

    try {
      const mpiResponse = await PostPatient(body);
      if (mpiResponse?.data) {
        if (type === "patient") {
          toast.success("Paciente criado com sucesso");
        } else {
          setDataResponsible(mpiResponse?.data);
          toast.success("Responsável criado com sucesso");
        }

        setValue("patientActor", {
          reference: `Patient/${mpiResponse?.data?.id}`,
          display: mpiResponse?.data?.name?.[0]?.text || "",
        });

        const auxObjReference = {
          relationship: watchAllFields?.relationship,
          ownerName: dataResponsible?.name?.[0]?.text,
          phoneNumber: dataResponsible?.telecom?.[0]?.value,
          gender: mpiResponse?.data?.gender,
        };

        const auxMpiResponse = {
          data: {
            id: mpiResponse?.data?.id,
            name: [
              {
                text: mpiResponse?.data?.name?.[0]?.text || "",
              },
            ],
          },
        };

        setValue("auxObjReference", auxObjReference);
        setValue("auxMpiResponse", auxMpiResponse);

        if (type === "patient") {
          if (dataResponsible) setPage(Steps.S4ConfirmRelationship);
          else if (walkin) setPage(Steps.S5SelectAppointmentActors);
          else setPage(Steps.S6ConfirmAppointment);
        } else {
          setPage(Steps.S3CreatePatient);
        }
      }
    } catch (err) {
      console.error("Error creating patient:", err);
      toast.error(
        `Erro ao criar ${type === "patient" ? "paciente" : "responsável"}`
      );
    } finally {
      setValue("gender", undefined);

      setLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <Stack spacing={2}>
          <>
            {minorPatient ? (
              <>
                <Box p={1}>
                  <FormTextInput
                    label="Nome completo"
                    placeholder="Nome completo"
                    name="name"
                    control={control}
                    required={true}
                    autoFocus
                  />
                </Box>
                <Box p={1}>
                  <DisplayTextField variant="filled" label="CPF" value={cpf} />
                </Box>
                <Box p={1}>
                  <FormDateInput
                    label="Data de nascimento"
                    placeholder="Data de nascimento"
                    name="birthDate"
                    control={control}
                    required={true}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box p={1}>
                  <DisplayTextField
                    variant="filled"
                    label="Nome completo"
                    value={serproResponse?.nome || ""}
                  />
                </Box>
                <Box p={1}>
                  <DisplayTextField
                    variant="filled"
                    label="CPF"
                    value={serproResponse?.ni || ""}
                  />
                </Box>
                <Box p={1}>
                  <DisplayTextField
                    variant="filled"
                    label="Data de nascimento"
                    value={
                      dayjs(serproResponse?.nascimento, "DDMMYYYY").format(
                        "DD/MM/YYYY"
                      ) || ""
                    }
                  />
                </Box>
              </>
            )}
          </>
          <Box p={1}>
            <FormTextInput
              label="Contato"
              placeholder="Número de telefone"
              name="phoneNumber"
              control={control}
              required={true}
              // defaultValue={useFormatter.formatPhoneNumber(
              //   dataResponsible?.telecom?.[0]?.value
              // )}
              pattern={useRegex.phoneNumber}
              mask={useFormatter.formatPhoneNumber}
              maxLength={15}
              // autoFocus
            />
          </Box>
          <Box p={1}>
            <FormSelectInput
              name="gender"
              control={control}
              label="Sexo de nascimento"
              selectOptions={typeOptionsGender}
              required
            />
          </Box>
          {minorPatient && (
            <Box display="flex" flexDirection="row" alignItems="center" p={1}>
              <Checkbox
                onChange={handleCheckbox}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                variant="body1"
                color="neutral700.main"
                fontWeight={500}
              >
                Estou ciente que o paciente é menor de idade
              </Typography>
            </Box>
          )}

          <FormButtonGroup
            onGoBackButtonClick={() => {
              reset();
              setPage(Steps.S1SelectPatient);
            }}
            onNextButtonClick={handleSubmit(createPatientMPI)}
            loading={loading}
            disabled={minorPatient ? !checkbox : false}
          />
        </Stack>
      ) : (
        <CircularLoading />
      )}
    </>
  );
}
