import { ReactNode, createContext, useContext, useEffect } from "react";
import { useQuestionnaireContext } from "./QuestionnaireContext";
import { useMedicalCareContext } from "./MedicalCareContext";
import { useFhirData } from "../utils/useFhirData";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

interface EditQuestionnaireContextValue {}

const smokingOptions = [
  {
    code: "LA18976-3",
    display: "atualmente, fumante diário",
    value: "Current every day smoker",
  },
  {
    code: "LA18977-1",
    display: "atualmente, fumante alguns dias na semana",
    value: "Current some day smoker",
  },
  { code: "LA15920-4", display: "ex-fumante", value: "Former smoker" },
  { code: "LA18978-9", display: "nunca fumou", value: "Never smoker" },
  {
    code: "LA18979-7",
    display: "estado tabagista atual desconhecido",
    value: "Smoker, current status unknown",
  },
  {
    code: "LA18980-5",
    display: "sem conhecimento se já fumou",
    value: "Unknown if ever smoked",
  },
  {
    code: "LA18981-3",
    display: "fumante frequente",
    value: "Heavy tobacco smoker",
  },
  {
    code: "LA18982-1",
    display: "fumante eventual",
    value: "Light tobacco smoker",
  },
  {
    code: "",
    display: "Limpar",
    value: "",
  },
];

const breastfeedingOptions = [
  {
    display: "Exclusivo",
    value: "exclusivo",
  },
  {
    display: "Predominante",
    value: "predominante",
  },
  {
    display: "Complementado",
    value: "complementado",
  },
  {
    display: "Inexistente",
    value: "inexistente",
  },
  {
    display: "Limpar",
    value: "",
  },
];

export const EditQuestionnaireContext =
  createContext<EditQuestionnaireContextValue>(null!);

export function EditQuestionnaireContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { state } = useLocation();
  const {
    questionnaireDefualt,
    defaultValues,
    response,
    questionnaire,
    loadingResponse,
  } = useQuestionnaireContext();
  const {
    condutas,
    summaryData,
    setCondutas,
    setValue,
    problems,
    setProblems,
    carePlan,
    setCarePlan,
    newExams,
    setNewExams,
  } = useMedicalCareContext();

  const responseEntries = response?.entry?.map((el: any) => el?.resource);

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry,
    encounterSummary
  );

  const observationDefault = filterEncounter?.filter((observation: any) => {
    return (
      observation?.encounter?.resource?.id === state?.encounterReference?.id
    );
  })?.[0]?.records;

  useEffect(() => {
    if (questionnaireDefualt && observationDefault) {
      const defaultValue = {
        weight: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Peso",
        }),
        alcoholicDrinksPerDay: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Consumo diário de álcool",
        }),
        tobaccoSmokingStatus: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Tabagismo",
        }),
        bodyHeight: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Altura",
        }),
        bodyMassIndex: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Índice de massa corporal",
        }),
        bloodPressureSystolic: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Pressão Arterial Sistólica e Diastólica",
        }),
        bloodPressureDiastolic: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Pressão Arterial Sistólica e Diastólica",
        }),
        waistCircumferenceAtUmbilicus: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Circunferência Abdominal",
        }),
        headOccipitalFrontalCircumference: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Perímetro Cefálico",
        }),
        numberOfAbortions: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Quantidade de Abortos",
        }),
        birthsTotal: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Partos",
        }),
        numberOfPregnancies: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Quantidade de Gestações",
        }),
        breastfeedingStatus: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Tipo de Aleitamento Materno",
        }),
        gestationalAgeEstimate: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Idade Gestacional",
        }),
        lastMenstrualPeriod: useFhirData?.extractObservation({
          Observations: observationDefault,
          text: "Data da Última Menstruação",
        }),
      };

      setValue(
        `Anthropometric.pressure.sistolic`,
        defaultValue?.bloodPressureSystolic?.[0]?.valueQuantity?.value || ""
      );
      setValue(
        `Anthropometric.pressure.diastolic`,
        defaultValue?.bloodPressureDiastolic?.[1]?.valueQuantity?.value || ""
      );
      setValue(`Anthropometric.bodyWeight`, defaultValue?.weight?.value || "");
      setValue(
        `Anthropometric.bodyHeight`,
        defaultValue?.bodyHeight?.value || ""
      );
      setValue(
        `Anthropometric.abdominalCircumference`,
        defaultValue?.waistCircumferenceAtUmbilicus?.value || ""
      );
      setValue(
        `Anthropometric.headCircumference`,
        defaultValue?.headOccipitalFrontalCircumference?.value || ""
      );
      setValue(
        `Anthropometric.alcoholic`,
        defaultValue?.alcoholicDrinksPerDay?.value || ""
      );
      setValue(
        `Anthropometric.smoking`,
        smokingOptions?.find(
          (option: any) =>
            option?.code ===
            defaultValue?.tobaccoSmokingStatus?.coding?.[0]?.code
        )
      );
      setValue(
        `Anthropometric.menstrualPeriod`,
        defaultValue?.lastMenstrualPeriod
          ? dayjs(defaultValue?.lastMenstrualPeriod)
          : ""
      );
      setValue(
        `Anthropometric.pregnancies`,
        defaultValue?.numberOfPregnancies?.value || ""
      );
      setValue(
        `Anthropometric.abortions`,
        defaultValue?.numberOfAbortions?.value || ""
      );
      setValue(
        `Anthropometric.birthsTotal`,
        defaultValue?.birthsTotal?.value || ""
      );
      setValue(
        `Anthropometric.breastfeeding`,
        breastfeedingOptions?.find(
          (item) =>
            item?.value === defaultValue?.breastfeedingStatus?.coding?.[0]?.code
        ) || ""
      );
      setValue(
        `Anthropometric.ageGestacional`,
        defaultValue?.gestationalAgeEstimate?.value || ""
      );
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    if (questionnaireDefualt) {
      const anamneseItens = questionnaire?.item?.find(
        (el: any) => el.text === "Anamnese"
      )?.item;

      anamneseItens?.map((e: any) => {
        const awnser = useFhirData?.extractAnswer({
          questionnaireResponseItems: defaultValues?.[0]?.item,
          targetId: e.linkId,
        });

        setValue(`anamnese.${e.text}`, awnser);

        return { question: e?.text, awnser };
      });
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    if (questionnaireDefualt) {
      const examsItens = questionnaire?.item?.find(
        (el: any) => el.text === "Exames Físicos"
      );

      const neurologicFields = examsItens?.item?.find(
        (el: any) => el.text === "Neurológico"
      )?.item;

      neurologicFields?.map((e: any) => {
        const awnserCode = useFhirData?.extractAnswer({
          questionnaireResponseItems: defaultValues?.[1]?.item?.[1]?.item,
          targetId: e?.linkId,
          onlyAnswer: true,
        })?.valueCoding?.code;

        const awnserValue = useFhirData?.extractAnswer({
          questionnaireResponseItems: defaultValues?.[1]?.item?.[1]?.item,
          targetId: e?.linkId,
          onlyAnswer: true,
        })?.item?.[0]?.answer?.[0]?.valueString;

        setValue(
          `exams.neurologico.${e?.text.replace(".", " ")}.value`,
          awnserCode
        );
        setValue(
          `exams.neurologico.${e?.text.replace(".", " ")}.alterado`,
          awnserValue === "undefined" ? undefined : awnserValue
        );

        return { code: awnserCode, value: awnserValue };
      });
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    if (questionnaireDefualt) {
      const examsItens = questionnaire?.item?.find(
        (el: any) => el.text === "Exames Físicos"
      );

      const generalFields = examsItens?.item?.find(
        (el: any) => el.text === "Geral"
      )?.item;
      generalFields?.map((e: any) => {
        const awnserCode = useFhirData?.extractAnswer({
          questionnaireResponseItems: defaultValues?.[1]?.item?.[0]?.item,
          targetId: e?.linkId,
          onlyAnswer: true,
        })?.valueCoding?.code;

        const awnserValue = useFhirData?.extractAnswer({
          questionnaireResponseItems: defaultValues?.[1]?.item?.[0]?.item,
          targetId: e?.linkId,
          onlyAnswer: true,
        })?.item?.[0]?.answer?.[0]?.valueString;

        setValue(`exams.geral.${e?.text.replace(".", " ")}.value`, awnserCode);
        setValue(
          `exams.geral.${e?.text.replace(".", " ")}.alterado`,
          awnserValue === "undefined" ? undefined : awnserValue
        );

        return { code: awnserCode, value: awnserValue };
      });
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    const dataExamsResults = defaultValues?.[3]?.item;

    if (
      !newExams?.length &&
      dataExamsResults?.length &&
      questionnaireDefualt &&
      !loadingResponse
    ) {
      const defaultExamsInputs = dataExamsResults?.map(
        (DataExams: any, index: number) => {
          const answerName = useFhirData?.extractAnswer({
            questionnaireResponseItems: DataExams?.item,
            targetId: "801d90b0-0fd1-410f-89e5-d26087895039",
          });
          const answerValue = useFhirData?.extractAnswer({
            questionnaireResponseItems: DataExams?.item,
            targetId: "4a58a481-b19a-41a5-9865-d3e0821b1005",
          });
          const answerDate = useFhirData?.extractAnswer({
            questionnaireResponseItems: DataExams?.item,
            targetId: "91b753c5-b71c-4a66-86eb-c24c91205a26",
          });

          setValue(`newExames.${index}.name`, answerName);
          setValue(`newExames.${index}.value`, answerValue);
          setValue(
            `newExames.${index}.date`,
            answerDate ? dayjs(answerDate) : dayjs()
          );

          return {
            nameId: `newExames.${index}.name`,
            valueId: `newExames.${index}.value`,
            dateId: `newExames.${index}.date`,
          };
        }
      );
      setNewExams(defaultExamsInputs);
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    const dataTreatmentPlans = defaultValues?.[5]?.item;

    const getStatusConfig = (StatusType: string) => {
      if (StatusType === "Concluído") {
        return 1;
      }
      if (StatusType === "Parcialmente") {
        return 2;
      }
      if (StatusType === "Falhou") {
        return 3;
      }

      return undefined;
    };

    const getTypeTreatment = (StatusType: any) => {
      if (StatusType === "Longo Prazo") {
        return 1;
      }
      if (StatusType === "Curto Prazo") {
        return 2;
      }

      return undefined;
    };

    const treatmentPlansResponses = responseEntries?.map((el: any) => {
      const awnserDate = el?.authored;
      const filterItens = el?.item
        ?.filter((e: any) => e?.text === "Planos de tratamento")?.[0]
        ?.item?.filter((subItem: any) => {
          return subItem?.item?.[0]?.answer;
        });
      const displayReferences = el?.item?.filter(
        (e: any) => e?.linkId === "displayReferences"
      )?.[0]?.item;
      const displayInfo = {
        encounterId: el?.encounter?.reference?.split("/")?.[1],
        healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
        practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
        identifier: el?.author?.reference?.split("-")?.[1],
      };
      return { awnserDate, filterItens, displayInfo };
    });

    const flatOpenItens = treatmentPlansResponses
      ?.filter((el: any) => el.filterItens?.length && el.filterItens)
      ?.flatMap((item: any) => {
        const auxItem = item?.filterItens?.map((response: any) => {
          return {
            ...response,
            displayInfo: item?.displayInfo,
            awnserDate: item?.awnserDate,
          };
        });

        return auxItem;
      });

    const auxVerify = flatOpenItens?.filter((e: any) => {
      const value = e?.item?.[0]?.answer?.[0]?.valueString;
      const status = e?.item?.[2]?.answer?.[0]?.valueCoding;
      const auxVerifyEncounter =
        e?.displayInfo?.encounterId === state?.encounterReference?.id;

      const auxDuplicate = flatOpenItens?.some((el: any) => {
        const valueToCompare = el?.item?.[0]?.answer?.[0]?.valueString;
        const statusToCompare = el?.item?.[2]?.answer?.[0]?.valueCoding;
        return statusToCompare && valueToCompare.includes(value);
      });

      if (auxVerifyEncounter && status) {
        return true;
      } else {
        return !auxDuplicate && !status && !auxVerifyEncounter;
      }
    });

    if (
      carePlan?.length === 1 &&
      dataTreatmentPlans?.length &&
      questionnaireDefualt &&
      !loadingResponse
    ) {
      const defaultTreatmentPlans = dataTreatmentPlans
        ?.filter((treatmentPlan: any) => {
          return !useFhirData?.extractAnswer({
            questionnaireResponseItems: treatmentPlan?.item,
            targetId: "ba000da7-3e8e-4b03-faf2-4089aeb2852d",
          });
        })
        ?.map((treatmentPlan: any, index: number) => {
          const answerData = useFhirData?.extractAnswer({
            questionnaireResponseItems: treatmentPlan?.item,
            targetId: "477c970e-a76b-4fe9-89d4-65cd19481b7b",
          });
          const answerStatus = useFhirData?.extractAnswer({
            questionnaireResponseItems: treatmentPlan?.item,
            targetId: "a55cf057-e01e-423c-d27c-7f37b6e63e21",
          })?.display;

          setValue(`newPlan.${index}.plano`, answerData);
          setValue(`newPlan.${index}.prazo`, getTypeTreatment(answerStatus));

          return { answerData, answerStatus };
        });

      setCarePlan(defaultTreatmentPlans);
    }
    if (auxVerify?.length) {
      auxVerify.map((treatmentPlan: any, index: number) => {
        const value = treatmentPlan?.item?.[0]?.answer?.[0]?.valueString;
        const type = treatmentPlan?.item?.[1]?.answer?.[0]?.valueCoding?.code;
        const statusDiplay =
          treatmentPlan?.item?.[2]?.answer?.[0]?.valueCoding?.display;
        const nota = treatmentPlan?.item?.[3]?.answer?.[0]?.valueString;

        setValue(
          `CarePlans.${index}_${value};${type}.status`,
          getStatusConfig(statusDiplay)
        );
        setValue(`CarePlans.${index}_${value};${type}.nota`, nota || "");
      });
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    const getStatusConfig = (StatusType: string) => {
      if (StatusType === "Melhora") {
        return 1;
      }
      if (StatusType === "Estável") {
        return 2;
      }
      if (StatusType === "Piora") {
        return 3;
      }

      return undefined;
    };

    const dataProblems = defaultValues?.[4]?.item;
    const problemsReponses = responseEntries?.map((el: any) => {
      const awnserDate = el?.authored;
      const filterItens = el?.item
        ?.filter((e: any) => e?.text === "Lista de Problemas")?.[0]
        ?.item?.filter((subItem: any) => {
          return subItem?.item?.[0]?.answer;
        });
      const displayReferences = el?.item?.filter(
        (e: any) => e?.linkId === "displayReferences"
      )?.[0]?.item;
      const displayInfo = {
        encounterId: el?.encounter?.reference?.split("/")?.[1],
        healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
        practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
        identifier: el?.author?.reference?.split("-")?.[1],
      };
      return { awnserDate, filterItens, displayInfo };
    });

    const flatOpenItens = problemsReponses
      ?.filter((el: any) => el.filterItens?.length)
      ?.flatMap((item: any) => {
        const auxItem = item?.filterItens?.map((response: any) => {
          return {
            ...response,
            displayInfo: item?.displayInfo,
            awnserDate: item?.awnserDate,
          };
        });

        return auxItem;
      });

    const auxVerify = flatOpenItens?.filter((e: any) => {
      const value = e?.item?.[0]?.answer?.[0]?.valueString;
      const status = e?.item?.[1]?.answer?.[0]?.valueCoding;
      const auxVerifyEncounter =
        e?.displayInfo?.encounterId === state?.encounterReference?.id;

      const auxDuplicate = flatOpenItens?.some((el: any) => {
        const valueToCompare = el?.item?.[0]?.answer?.[0]?.valueString;
        const statusToCompare = el?.item?.[1]?.answer?.[0]?.valueCoding;
        return statusToCompare && valueToCompare.includes(value);
      });

      if (auxVerifyEncounter && status) {
        return true;
      } else {
        return !auxDuplicate && !status && !auxVerifyEncounter;
      }
    });

    if (
      !problems?.length &&
      questionnaireDefualt &&
      dataProblems?.length &&
      !loadingResponse
    ) {
      const defaultProblems = dataProblems
        ?.filter((problem: any) => {
          return !useFhirData?.extractAnswer({
            questionnaireResponseItems: problem?.item,
            targetId: "2e202c22-d5bc-4243-870e-d342cb9c1b8d",
          });
        })
        ?.map((problem: any, index: number) => {
          const answerData = useFhirData?.extractAnswer({
            questionnaireResponseItems: problem?.item,
            targetId: "4f96c740-f739-4758-8324-b5fd7893fcbd",
          });

          setValue(`New_Problema.${index}`, answerData);

          return answerData;
        });

      setProblems(defaultProblems);
    }

    if (auxVerify?.length) {
      auxVerify.map((problem: any, index: number) => {
        setValue(
          `ProblemasPassados.${index}_${problem?.item?.[0]?.answer?.[0]?.valueString}.status`,
          getStatusConfig(problem?.item?.[1]?.answer?.[0]?.valueCoding?.display)
        );

        setValue(
          `ProblemasPassados.${index}_${problem?.item?.[0]?.answer?.[0]?.valueString}.nota`,
          problem?.item?.[2]?.answer?.[0]?.valueString || ""
        );
      });
    }
  }, [questionnaireDefualt]);

  useEffect(() => {
    const condutasReponses = responseEntries?.map((el: any) => {
      const awnserDate = el?.authored;
      const filterItens = el?.item
        ?.filter((e: any) => e?.text === "Condutas")?.[0]
        ?.item?.filter((subItem: any) => {
          return subItem?.item?.[0]?.answer;
        });
      const displayReferences = el?.item?.filter(
        (e: any) => e?.linkId === "displayReferences"
      )?.[0]?.item;
      const displayInfo = {
        encounterId: el?.encounter?.reference?.split("/")?.[1],
        healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
        practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
        identifier: el?.author?.reference?.split("-")?.[1],
      };
      return { awnserDate, filterItens, displayInfo };
    });

    const flatOpenItens = condutasReponses
      ?.filter((el: any) => el?.filterItens?.length)
      ?.flatMap((item: any) => {
        // map para adicionar informações de profissional, healthcare e data ao item de cada resposta
        const auxItem = item?.filterItens?.map((response: any) => {
          return {
            ...response,
            displayInfo: item?.displayInfo,
            awnserDate: item?.awnserDate,
          };
        });

        return auxItem;
      });

    const auxVerify = flatOpenItens?.filter((e: any) => {
      const value = e?.item?.[0]?.answer?.[0]?.valueString;
      const status = e?.item?.[1]?.answer?.[0]?.valueCoding;
      const auxVerifyEncounter =
        e?.displayInfo?.encounterId === state?.encounterReference?.id;

      const auxDuplicate = flatOpenItens?.some((el: any) => {
        const valueToCompare = el?.item?.[0]?.answer?.[0]?.valueString;
        const statusToCompare = el?.item?.[1]?.answer?.[0]?.valueCoding;
        return statusToCompare && valueToCompare.includes(value);
      });

      if (auxVerifyEncounter && status) {
        return true;
      } else {
        return !auxDuplicate && !status && !auxVerifyEncounter;
      }
    });

    const getStatusConfig = (StatusType: string) => {
      if (StatusType === "Realizada") {
        return 1;
      }
      if (StatusType === "Parcialmente") {
        return 2;
      }
      if (StatusType === "Não realizada") {
        return 3;
      }

      return undefined;
    };

    const dataConducts = defaultValues?.[2]?.item;

    if (
      questionnaireDefualt &&
      !condutas?.length &&
      dataConducts?.length &&
      !loadingResponse
    ) {
      const defaultCondutas = dataConducts
        ?.filter((conduct: any) => {
          return !useFhirData?.extractAnswer({
            questionnaireResponseItems: conduct?.item,
            targetId: "2e202c22-d5bc-4243-870e-d342cb9c1b8d",
          });
        })
        ?.map((conduct: any, index: number) => {
          const answerData = useFhirData?.extractAnswer({
            questionnaireResponseItems: conduct?.item,
            targetId: "3cccab72-a2ed-46e9-ebce-a1c6532e4bca",
          });

          setValue(`New_Conduta.${index}`, answerData);

          return answerData;
        });

      setCondutas(defaultCondutas);
    }

    if (auxVerify?.length) {
      auxVerify.map((el: any, index: number) => {
        setValue(
          `CondutasPassadas.${index}_${el?.item?.[0]?.answer?.[0]?.valueString}.status`,
          getStatusConfig(el?.item?.[1]?.answer?.[0]?.valueCoding?.display)
        );

        setValue(
          `CondutasPassadas.${index}_${el?.item?.[0]?.answer?.[0]?.valueString}.nota`,
          el?.item?.[2]?.answer?.[0]?.valueString || ""
        );
      });
    }
  }, [questionnaireDefualt]);
  const contextValue = {};

  return (
    <EditQuestionnaireContext.Provider value={contextValue}>
      {children}
    </EditQuestionnaireContext.Provider>
  );
}

export function useEditQuestionnaireContext() {
  return useContext(EditQuestionnaireContext);
}
