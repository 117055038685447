import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSessionContext } from "../../../contexts/SessionContext";
import { GetAppointment } from "../../../services/fhir";
import { LargeContentBox } from "../../../components/common";
import { oids } from "../../../configs/Settings";
import { Schedule } from "../../../components/schedule";
import { DateFilterType } from "../../../utils/interfaces";
import { useDatetime } from "../../../utils/useDatetime";
import { useLocationContext } from "../../../contexts/LocationContext";
import { usePatientOrganizationContext } from "../../../contexts/PatientOrganizationContext";
import { SearchPatient } from "../../../components/schedule/SearchPatient/SearchPatient";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { PatientSearchActions } from "../../patientsearch/components/PatientSearchActions";
import { useFormatter } from "../../../utils/useFormatter";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { WhatsApp } from "@mui/icons-material";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { ModalFirstAccess } from "./ModalFirstAccess";
import { NoScheduleOptions } from "../NoScheduleOptions";

export function ProfessionalDashboard({ schedule }: any) {
  const {
    inviteDialog,
    user,
    access,
    firstAccessDialog,
    setFirstAccessDialog,
  } = useSessionContext();
  const { location, locationLoading } = useLocationContext();

  const { practitioner, userSpecialties } = usePractitionerRoleContext();
  const anotherPractitioner = practitioner?.find(
    (e: any) => !e?.resource?.id.includes(user.username)
  );
  const verifyOwner =
    access?.roles?.includes("manager") && access?.roles?.includes("owner");

  const { formatDateToISO, isDateFilterTypeDay, isSameYearAndMonthAsToday } =
    useDatetime;

  const { patientOrganization, refetchPatientOrganization } =
    usePatientOrganizationContext();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<Dayjs>(dayjs());
  const [filterType, setFilterType] = useState<DateFilterType>("day");
  const [appointments, setAppointments] =
    useState<fhir5.Bundle<fhir5.Appointment>>();

  const [openFirstAccess, setOpenFirstAcccess] = useState<boolean>(!schedule);

  const handleClose = () => {
    setFirstAccessDialog(false);
    setOpenFirstAcccess(false);
  };

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
    { column: "Telefone", rowName: "contact" },
  ];

  const rows = patientOrganization?.map((el: any) => {
    const identifier = useFormatter.formatCPF(el.resource?.id.split("-")[1]);
    const name =
      useFormatter.formatNameDisplay(el.resource?.name?.[0]?.text) || "-";
    const birthDate =
      el.resource?.birthDate &&
      `${useFormatter.formatDate(
        el.resource?.birthDate
      )} - ${useFormatter.formatAge(el.resource?.birthDate)} anos`;
    const gender = useFormatter.formatGender(el.resource?.gender) || "-";

    const contact = el?.resource?.telecom;

    const phone = contact?.find((e: any) => e.system === "phone")?.value;

    return {
      identifier,
      name,
      birthDate,
      ...(phone && {
        contact: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography mt={0.5}>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+55${phone}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
      gender,
    };
  });

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);

      const locationId = location
        ?.map(
          (element: fhir5.BundleEntry<fhir5.Location>) => element.resource?.id
        )
        ?.join(",");

      const professionalActor = `Practitioner/${oids.cpf}-${user.username}`;

      const startDate =
        isDateFilterTypeDay(filterType) || isSameYearAndMonthAsToday(dateFilter)
          ? formatDateToISO(dateFilter)
          : formatDateToISO(dateFilter.startOf("month"));

      const endDate = isDateFilterTypeDay(filterType)
        ? formatDateToISO(dateFilter)
        : formatDateToISO(dateFilter.endOf("month"));

      const appointmentResponse = await GetAppointment({
        scheduling: true,
        includePatient: true,
        includeLocation: true,
        status: ["booked"],
        actor: locationId,
        startDate,
        endDate,
        sort: "date",
        ...(professionalActor && { professionalActor: professionalActor }),
      });

      setAppointments(appointmentResponse);
      setLoading(false);
    }

    if (!locationLoading && location !== undefined) {
      fetchAppointments();
    }
    refetchPatientOrganization();
  }, [
    dateFilter,
    filterType,
    user.username,
    access.roles,
    formatDateToISO,
    isDateFilterTypeDay,
    isSameYearAndMonthAsToday,
    location,
    locationLoading,
    refetch,
  ]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "neutral0.main",
          borderRadius: "20px",
          mb: 2,
          padding: "0px 40px",
          pb: 4,
        }}
      >
        <Typography variant="h6" fontWeight={600} mt={3}>
          Buscar
        </Typography>
        <SearchPatient />

        {rows?.length && (
          <Accordion
            sx={{
              width: "100%",
              border: "1px solid",
              borderColor: "neutral200.main",
              borderRadius: 1,
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Meus pacientes
            </AccordionSummary>
            <AccordionDetails>
              <Stack width="100%" justifyContent="flex-start">
                <Typography variant="h6" fontWeight={600} mb={2}></Typography>
                <GenericTable
                  tableColumns={columns}
                  tableRows={rows?.reverse()}
                  actions={(rowData: any) => (
                    <PatientSearchActions row={rowData} />
                  )}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <LargeContentBox>
        {inviteDialog ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <>
            {!schedule ? (
              <NoScheduleOptions anotherPractitioner={anotherPractitioner} />
            ) : (
              <Schedule
                hasActiveSchedule={schedule?.find(
                  (e: any) => e?.resource?.active
                )}
                bundle={appointments}
                dateFilter={dateFilter}
                filterType={filterType}
                loading={loading}
                setDateFilter={setDateFilter}
                setFilterType={setFilterType}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}
          </>
        )}

        {!schedule &&
          verifyOwner &&
          !anotherPractitioner &&
          !userSpecialties &&
          firstAccessDialog &&
          !loading && (
            <ModalFirstAccess
              open={openFirstAccess}
              handleClose={handleClose}
            />
          )}
      </LargeContentBox>
    </>
  );
}
