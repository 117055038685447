import React from "react";
import { Box, Stack } from "@mui/material";
import { Header } from "./Header";
import Sidebar from "./Sidebar";
import { MobileBarNavigation } from "./MobileBarNavigation";

export function PrivateMainLayout({
  children,
  noSidebar = false,
  dashboard = false,
  mobileView = false,
  noPadding = false,
}: {
  children: React.ReactNode;
  noSidebar?: boolean;
  dashboard?: boolean;
  mobileView?: boolean;
  noPadding?: boolean;
}) {
  return (
    <Stack
      direction="column"
      width="100%"
      minHeight="100vh"
      justifyContent="center"
      flex="1"
    >
      <Box width="100%" position="fixed" sx={{ top: 0, zIndex: "100" }}>
        <Header />
      </Box>
      <Stack direction="row" width="100%" flex="1" mt={7}>
        {!noSidebar && <Sidebar />}
        <Stack
          flex="1"
          paddingX={{
            xs: noPadding ? 0 : 3,
            sm: noPadding ? 0 : dashboard ? 3 : noSidebar ? "10%" : "5%",
            md: noPadding ? 0 : dashboard ? 3 : noSidebar ? "5%" : "1%",
          }}
          paddingY={noPadding ? 0 : 3}
          margin={0}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ backgroundColor: "primary100.main" }}
        >
          {children}
        </Stack>
      </Stack>
      <Box
        width="100%"
        height="5vh"
        component="footer"
        position="fixed"
        bottom={0}
        zIndex={1}
      >
        {mobileView && <MobileBarNavigation />}
      </Box>
    </Stack>
  );
}
