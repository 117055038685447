import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { Delete, Edit } from "@mui/icons-material";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { toast } from "react-toastify";
import { PatchEHRPoster } from "../../../../services/fhir/patientsummary/PatchEHRPoster";
import { ModalConfirm } from "./ModalConfirm";
import { useFhirData } from "../../../../utils/useFhirData";
import { useSessionContext } from "../../../../contexts/SessionContext";

const styleCriticality: any = {
  Baixo: "#FBBB3C",
  Alto: "#EF4352",
  "Incapaz de avaliar": "#ccc",
};

const styleClinicalStatus: any = {
  Ativo: "#EF4352",
  Inativo: "#FBBB3C",
  Resolvido: "#007C6D",
  Recorrente: "#FBBB3C",
  Remissão: "#EF4352",
  Desconhecido: "#ccc",
};

const styleCategory: any = {
  Alimento: "#a6AA10",
  Medicamento: "#1aA0b8",
  Ambiente: "#006af5",
  Biológico: "#a68310",
};

const styleVerificationStatus: any = {
  Confirmado: "#007C6D",
  Presumido: "#FBBB3C",
  Refutado: "#007C6D",
  "Não confirmado": "",
};

export function DiagnosticosAccordion({
  page,
  data,
  index,
  setEditCondition,
  reset,
}: any) {
  const { user } = useSessionContext();
  const { loadingDiagnostics } = useMedicalCareContext();
  const { setRefetch } = useMedicalCareContext();
  const [modalConfirmDelete, setModalConfirmDelete] = useState<boolean>(false);
  const [auxDataDelete, setAuxDataDelete] = useState<any>();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const handleDeleteClick = async (data: any) => {
    setLoadingDelete(true);
    try {
      const response = await PatchEHRPoster({
        resourceType: page === 0 ? "Condition" : "AllergyIntolerance",
        resourceId: data?.id,
        operation: "replace",
        path: "/verificationStatus/coding/0/code",
        value: "entered-in-error",
      });
      if (response && response.status === HTTPResponseStatus.Success) {
        toast.success("Diagnóstico removido com sucesso!");
      }
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("Um erro ocorreu ao tentar remover registro.");
    } finally {
      setModalConfirmDelete(false);
      setLoadingDelete(false);
    }
    setRefetch(true);
  };

  const handleEditClick = (data: any) => {
    setEditCondition(data);
  };

  return (
    <>
      {loadingDiagnostics && index === 0 ? (
        <Box
          sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 2,
          }}
        >
          <Skeleton variant="text" width={"100%"} sx={{ fontSize: "1rem" }} />
          <Stack direction="row" spacing={12} sx={{ my: 2 }}>
            <Skeleton variant="rounded" width={"100%"} height={32} />
            <Skeleton variant="rounded" width={"100%"} height={32} />
          </Stack>

          <Divider></Divider>
          <Stack direction="row" spacing={2}>
            <Skeleton
              variant="text"
              width={"75%"}
              sx={{ fontSize: "1.2rem" }}
            />
            <Skeleton variant="text" width={"25%"} sx={{ fontSize: "1rem" }} />
          </Stack>
        </Box>
      ) : (
        <>
          <Accordion
            defaultExpanded={true}
            expanded={true}
            sx={{
              border: "1px solid #ccc",
              boxShadow: "none",
              borderColor: styleCriticality[data?.criticality],
              borderRadius: 1,
            }}
          >
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <Stack
                direction="row"
                sx={{ alignItems: "center", width: "100%" }}
                justifyContent="space-between"
              >
                <Typography fontWeight={500} color={"neutral700.main"}>
                  {data.description} {data?.cid10 ? `- ${data.cid10}` : ""}
                </Typography>
                {useFhirData
                  .findParticipant(
                    data?.fullResource?.participant,
                    "Practitioner"
                  )
                  ?.reference?.includes(user?.username) && (
                  <Stack direction="row">
                    <Tooltip title="Editar">
                      <IconButton
                        sx={{ color: "neutral1000.main" }}
                        onClick={() => handleEditClick(data)}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remover">
                      <IconButton
                        onClick={() => {
                          setEditCondition(null);
                          reset();
                          setAuxDataDelete(data);
                          setModalConfirmDelete(true);
                        }}
                      >
                        <Delete
                          fontSize="small"
                          sx={{ color: "error700.main" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container columnSpacing={{ xs: 8 }} rowSpacing={{ xs: 2 }}>
                <Grid xs={12} item>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    {data?.clinicalStatus && (
                      <Stack direction="column" alignItems="center">
                        <Typography
                          color={"neutral700.main"}
                          fontWeight={500}
                          fontSize={12}
                          mx={1}
                        >
                          Status clínico:
                        </Typography>
                        <Chip
                          label={data?.clinicalStatus}
                          size="small"
                          variant="outlined"
                          sx={{
                            width: "fit-content",
                            color: styleClinicalStatus[data?.clinicalStatus],
                            borderColor:
                              styleClinicalStatus[data?.clinicalStatus],
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                        />
                      </Stack>
                    )}

                    {data?.verificationStatus && (
                      <Stack direction="column" alignItems="center">
                        <Typography
                          color={"neutral700.main"}
                          fontWeight={500}
                          fontSize={12}
                          mx={1}
                        >
                          Status de verificação:
                        </Typography>
                        <Chip
                          label={data?.verificationStatus}
                          size="small"
                          variant="outlined"
                          sx={{
                            width: "fit-content",
                            color:
                              styleVerificationStatus[data?.verificationStatus],
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                        />
                      </Stack>
                    )}
                    {data?.category && (
                      <Stack direction="column" alignItems="center">
                        <Typography
                          color={"neutral700.main"}
                          fontWeight={500}
                          fontSize={12}
                          mx={1}
                        >
                          Categoria:
                        </Typography>
                        <Chip
                          label={data?.category}
                          size="small"
                          variant="outlined"
                          sx={{
                            width: "fit-content",
                            color: styleCategory[data?.category],
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                        />
                      </Stack>
                    )}
                    {data?.criticality && (
                      <Stack direction="column" alignItems="center">
                        <Typography
                          color={"neutral700.main"}
                          fontWeight={500}
                          fontSize={12}
                          mx={1}
                        >
                          Criticidade:
                        </Typography>
                        <Chip
                          label={data?.criticality}
                          size="small"
                          variant="outlined"
                          sx={{
                            width: "fit-content",
                            color: styleCriticality[data?.criticality],
                            borderColor: styleCriticality[data?.criticality],
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                  {data?.fullResource?.note?.[0]?.text && (
                    <Stack mt={2}>
                      <Typography
                        style={{
                          backgroundColor: "#eeeeee",
                          color: "#757575",
                          padding: 6,
                          borderRadius: 8,
                          wordBreak: "break-word",
                        }}
                      >
                        {data?.fullResource?.note?.[0]?.text}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                mb={2}
              ></Stack>

              <Divider sx={{ my: 2 }}></Divider>
              <Stack
                mt={2}
                direction="row"
                width="100%"
                alignItems="center"
                spacing={2}
              >
                {data?.displayInfo?.practitioner && (
                  <Typography
                    color="neutral700.main"
                    sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
                  >
                    Dr. {data?.displayInfo?.practitioner}
                  </Typography>
                )}

                {data?.displayInfo?.healthcare && (
                  <Chip
                    label={data?.displayInfo?.healthcare || "Sem especialidade"}
                    size="small"
                    sx={{
                      color: data?.displayInfo?.healthcare
                        ? "neutral700.main"
                        : "neutral500.main",

                      fontWeight: 500,
                      backgroundColor: data?.displayInfo?.healthcare
                        ? ""
                        : "#f1f1f1",
                    }}
                  />
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {modalConfirmDelete && (
        <ModalConfirm
          data={data}
          open={modalConfirmDelete}
          setModalOpen={setModalConfirmDelete}
          loading={loadingDelete}
          handleDelete={() => handleDeleteClick(auxDataDelete)}
        />
      )}
    </>
  );
}
