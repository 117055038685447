import React from "react";
import { Stack } from "@mui/material";

import { HeaderLogo, HeaderStack } from "../../../layoutHeader";
import { OptionsMenu } from "./options";
import { OrganizationSelect } from "../../../layoutHeader/OrganizationSelect";
import { isMobile } from "react-device-detect";
import { OptionsMenuMobile } from "./options/OptionsMenuMobile";
import { Notifications } from "../../../notification";
import { SupportFeedback } from "../../../supportFeedback/SupportFeedback";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { SignatureControls } from "./SignatureControls";

export function Header() {
  const { access } = useSessionContext();
  return (
    <HeaderStack>
      <Stack direction="row" alignItems="center" spacing={10}>
        <HeaderLogo />
        {access?.type === "professional" && <SignatureControls />}
      </Stack>

      <Stack
        key="private-user-layout-header-right"
        direction="row"
        // spacing={4}
        alignItems="center"
      >
        {/* <SupportFeedback /> */}
        <Stack paddingRight={4} paddingLeft={4}>
          <OrganizationSelect />
        </Stack>
        <Notifications />
        {!isMobile ? <OptionsMenu /> : <OptionsMenuMobile />}
      </Stack>
    </HeaderStack>
  );
}
