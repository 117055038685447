import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { Dispatch, SetStateAction, useState } from "react";
import {
  FormButtonGroup,
  FormMultipleAutoComplete,
  FormTextInput,
} from "../../../components/form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useRegex } from "../../../utils/useRegex";
import { useFormatter } from "../../../utils/useFormatter";
import {
  GetPractitioner,
  PutPractitioner,
  PutPractitionerRole,
} from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { oids } from "../../../configs/Settings";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { SendEmail } from "../../../services/brevo";
import { SearchCPF } from "../../../services/scim";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  practitioners: any;
}

const defaultValues = {
  email: "",
  identifier: "",
  phone: "",
};

export function InviteDialog({ isOpen, setIsOpen, practitioners }: IProps) {
  const { organization } = useOrganizationContext();
  const { refetchPractitionerRole } = usePractitionerRoleContext();

  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues });
  const { access } = useSessionContext();

  const { handleSubmit, control, reset } = methods;

  const rolesOptions = [
    { display: "Profissional de saúde", value: "practitioner" },
    { display: "Secretariado", value: "manager" },
  ];

  function transformData(inputData: string) {
    const day = inputData.substring(0, 2);
    const month = inputData.substring(2, 4);
    const year = inputData.substring(4, 8);

    const formatedDate = [year, month, day]?.join("-");

    return formatedDate;
  }

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
  };

  const onSubmit = async (data: any) => {
    const practitionerExists = practitioners?.some((el: any) =>
      useFormatter.formatCPF(el?.resource?.id.split("-")[2]) === data.identifier
        ? true
        : false
    );

    if (!practitionerExists) {
      setLoading(true);
      try {
        const verifyPracitioner = await GetPractitioner(
          data?.identifier.replace(/[.-]/g, "")
        );
        if (verifyPracitioner) {
          await PutPractitionerRole({
            organizationId: `${oids.cnpj}-${access.organizationId}`,
            organizationDisplay: organization?.name || "",
            practitionerId: verifyPracitioner.id,
            practitionerDisplay: verifyPracitioner?.name?.[0].text,
            role: data?.role?.map((el: any) => el?.value),
            invite: true,
          });
        } else {
          const invitedData = await SearchCPF(
            data?.identifier.replace(/[.-]/g, "")
          );
          if (invitedData?.data?.situacao?.descricao === "Regular") {
            const createdPractitioner = await PutPractitioner({
              identifier: data?.identifier.replace(/[.-]/g, ""),
              name: invitedData?.data?.nome,
              birthDate: transformData(invitedData?.data?.nascimento),
            });
            await PutPractitionerRole({
              organizationId: `${oids.cnpj}-${access.organizationId}`,
              organizationDisplay:
                organization?.alias?.[0] || organization?.name || "",
              practitionerId: createdPractitioner.id,
              practitionerDisplay: invitedData?.data?.nome,
              invite: true,
            });
          } else throw new Error("CPF não encontrado ou situação irregular");
        }
        await SendEmail(
          { email: data.contact },
          data.inviteMessage || "",
          organization?.name
        );
        refetchPractitionerRole();
        handleClose();
        toast.success(
          `Convite enviado com sucesso para o profissional de CPF: ${data?.identifier}`
        );
      } catch (err) {
        console.log("err", err);
        toast.warn(`CPF não encontrado ou situação irregular`);
      } finally {
        setLoading(false);
      }
    } else {
      handleClose();
      toast.warning("CPF já cadastrado ou aguardando confirmação!");
    }
  };

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center"> Convidar profissional </DialogTitle>
      <DialogContent>
        <Stack
          width="100%"
          height="100%"
          flex="1"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="center"
            m={{ xs: 2, md: 5 }}
          >
            Informe os dados do profissional que deseja convidar para sua
            clínica
          </Typography>

          <FormTextInput
            required
            name="identifier"
            control={control}
            label="CPF"
            maxLength={14}
            pattern={useRegex.cpf}
            mask={useFormatter.formatCPF}
            autoFocus
          />

          <Stack direction="row" width="100%" spacing={2}>
            <FormTextInput
              required
              name="contact"
              control={control}
              pattern={useRegex.email}
              label="Email"
              autoFocus
            />
          </Stack>

          <Stack direction="row" width="100%" spacing={2}>
            <FormMultipleAutoComplete
              required
              variant="filled"
              control={control}
              // defaultValue={row?.paper}
              label="Função"
              name="role"
              selectOptions={[
                { label: "Proprierário", value: "owner" },
                { label: "Secretariado", value: "manager" },
                { label: "Profissional de saúde", value: "practitioner" },
              ].sort((el: any, val: any) => (el.label < val.label ? 1 : -1))}
            />
          </Stack>

          <Stack direction="row" width="100%" spacing={2}>
            <FormTextInput
              multiline={true}
              rows={6}
              name="inviteMessage"
              control={control}
              label="Mensagem"
              autoFocus
            />
          </Stack>
        </Stack>

        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Voltar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
