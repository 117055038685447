import { oids } from "../../../../configs/Settings";

export const resultExamPhysical = (resultData: any) => {
  if (resultData?.value === "alterado") {
    return {
      code: "alterado",
      display: "Alterado",
      system: "urn:uuid:35a306cd-8fcb-405f-8dfb-63501d0a5815",
    };
  }
  if (resultData?.value === "not_evaluated" || !resultData?.value) {
    return {
      code: "not_evaluated",
      display: "Não avaliado",
      system: "urn:uuid:not_evaluated",
    };
  }
  if (resultData?.value === "normal") {
    return {
      code: "normal",
      display: "Normal",
      system: "urn:uuid:35a306cd-8fcb-405f-8dfb-63501d0a5815",
    };
  }
};

export const resultConducts = (
  resultConductsData: any,
  oldConductsData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Realizada" },
      2: { code: "partially", display: "Parcialmente" },
      3: { code: "failed", display: "Não realizada" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createConductItem = (
    conduct: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem("3cccab72-a2ed-46e9-ebce-a1c6532e4bca", "Conduta", [
        { valueString: conduct },
      ]),
      statusAnswer &&
        createItem("2e202c22-d5bc-4243-870e-d342cb9c1b8d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("3013e032-4b24-46fa-879a-29e775153a72", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "a6f78af6-6fd9-49a7-e606-491065403d2d",
      text: "Conduta",
      item: items,
    };
  };

  const oldConducts = oldConductsData
    ? Object.entries(oldConductsData)
        .map(([conduct, conducts]: [string, any]) => {
          if (conducts?.status) {
            const statusAnswer = status(conducts.status);
            const conductValue = conduct?.split("_")?.[1]?.split(";")?.[0];
            return createConductItem(conductValue, statusAnswer, conducts.nota);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newConducts = resultConductsData
    ? resultConductsData
        .map((conduct: any) => (conduct ? createConductItem(conduct) : null))
        .filter(Boolean) // Remove valores nulos
    : [];

  return [...newConducts, ...oldConducts];
};

export const resultProblems = (
  resultProblemsData: any,
  oldProblemsData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Melhora" },
      2: { code: "partially", display: "Estável" },
      3: { code: "failed", display: "Piora" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createProblemItem = (
    problem: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem("4f96c740-f739-4758-8324-b5fd7893fcbd", "Problema", [
        { valueString: problem },
      ]),
      statusAnswer &&
        createItem("2972b2f4-a634-4277-8200-96ce9111dc3d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("91b753c5-b71c-4a66-86eb-c24c91205a26", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "8e2c9b72-bcee-4c0f-ecb2-8e4fc2866cd4",
      text: "Problema",
      item: items,
    };
  };

  const oldProblems = oldProblemsData
    ? Object.entries(oldProblemsData)
        .map(([problem, problems]: [string, any]) => {
          if (problems?.status) {
            const statusAnswer = status(problems.status);
            const problemValue = problem?.split("_")?.[1]?.split(";")?.[0];
            return createProblemItem(problemValue, statusAnswer, problems.nota);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newProblems = resultProblemsData
    ? resultProblemsData
        .map((problem: any) => (problem ? createProblemItem(problem) : null))
        .filter(Boolean)
    : [];

  return [...newProblems, ...oldProblems];
};

export const resultExams = (
  resultExamResultsData: any,
  oldExamResultsData: any
) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createExamResultsItem = (name: any, resultValue: any, date: any) => {
    const items = [
      createItem("801d90b0-0fd1-410f-89e5-d26087895039", "Nome do exame", [
        { valueString: name },
      ]),
      createItem("4a58a481-b19a-41a5-9865-d3e0821b1005", "Valor do resultado", [
        { valueString: resultValue },
      ]),
      createItem("91b753c5-b71c-4a66-86eb-c24c91205a26", "Data de realização", [
        { valueDate: date },
      ]),
    ].filter(Boolean);

    return {
      linkId: "b7206ad2-06a0-4b3f-8529-ca1be08ce24b",
      text: "Exame",
      item: items,
    };
  };

  const oldExamResults = oldExamResultsData
    ? Object.entries(oldExamResultsData)
        ?.map(([name, values]: [string, any]) => {
          const examValues = values?.filter(
            (value: any) => value !== undefined
          );

          return examValues
            ? examValues?.map((examValues: any) => {
                return createExamResultsItem(
                  name,
                  examValues?.value,
                  examValues?.date
                );
              })
            : null;
        })
        ?.filter(Boolean)
        ?.flat()
    : [];

  const newExamResults = resultExamResultsData
    ? resultExamResultsData
        .map((examResult: any) =>
          examResult
            ? createExamResultsItem(
                examResult?.name,
                examResult?.value,
                examResult?.date
              )
            : null
        )
        .filter(Boolean)
    : [];

  return [...newExamResults, ...oldExamResults];
};

export const resultTreatmentPlans = (
  resultTreatmentPlansData: any,
  oldTreatmentPlansData: any
) => {
  const status = (status: number) => {
    const statusMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "concluded", display: "Concluído" },
      2: { code: "partially", display: "Parcialmente" },
      3: { code: "failed", display: "Falhou" },
    };

    return statusMap[status]
      ? {
          valueCoding: {
            ...statusMap[status],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const typeTreatment = (type: number) => {
    const typeMap: { [key: number]: { code: string; display: string } } = {
      1: { code: "long-term", display: "Longo Prazo" },
      2: { code: "short-term", display: "Curto Prazo" },
    };

    return typeMap[type]
      ? {
          valueCoding: {
            ...typeMap[type],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const typeTreatmentNumber = (type: string) => {
    const typeMap: { [key: string]: { code: string; display: string } } = {
      "long-term": { code: "long-term", display: "Longo Prazo" },
      "short-term": { code: "short-term", display: "Curto Prazo" },
    };

    return typeMap[type]
      ? {
          valueCoding: {
            ...typeMap[type],
            system: "urn:uuid:0dd760d6-b46c-43fe-9ecc-77c80cce397a",
          },
        }
      : null;
  };

  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const createTreatmentPlansItem = (
    treatmentPlan: any,
    typeAnswer?: any,
    statusAnswer?: any,
    nota?: string
  ) => {
    const items = [
      createItem(
        "477c970e-a76b-4fe9-89d4-65cd19481b7b",
        "Plano de tratamento",
        [{ valueString: treatmentPlan }]
      ),
      typeAnswer &&
        createItem("a55cf057-e01e-423c-d27c-7f37b6e63e21", "Prazo do Plano", [
          typeAnswer,
        ]),
      statusAnswer &&
        createItem("ba000da7-3e8e-4b03-faf2-4089aeb2852d", "Status", [
          statusAnswer,
        ]),
      nota &&
        createItem("c04e0f73-2548-4457-a723-a7a403797688", "Nota", [
          { valueString: nota },
        ]),
    ].filter(Boolean);

    return {
      linkId: "4e1679c2-3e1d-444d-d438-28bdbeb1e0ee",
      text: "Planos de tratamento",
      item: items,
    };
  };

  const oldTreatmentPlans = oldTreatmentPlansData
    ? Object.entries(oldTreatmentPlansData)
        .map(([treatmentPlan, treatmentPlanValues]: [string, any]) => {
          if (treatmentPlanValues?.status) {
            const prazo = treatmentPlan?.split(";")?.[1];
            const statusAnswer = status(treatmentPlanValues?.status);
            const typeAnswer = typeTreatmentNumber(prazo);
            const treatmentPlanOld = treatmentPlan
              ?.split("_")?.[1]
              ?.split(";")?.[0];

            return createTreatmentPlansItem(
              treatmentPlanOld,
              typeAnswer,
              statusAnswer,
              treatmentPlanValues?.nota
            );
          }
          return null;
        })
        .filter(Boolean)
    : [];
  const newTreatmentPlans = resultTreatmentPlansData
    ? resultTreatmentPlansData
        .map((treatmentPlan: any) => {
          if (treatmentPlan?.prazo && treatmentPlan?.plano) {
            const typeAnswer = typeTreatment(treatmentPlan?.prazo);
            const treatmentPlanNew = treatmentPlan?.plano;

            return createTreatmentPlansItem(treatmentPlanNew, typeAnswer);
          }
          return null;
        })
        .filter(Boolean)
    : [];
  return [...newTreatmentPlans, ...oldTreatmentPlans];
};

export const resultAnamnesis = (data: any) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const items = [
    createItem("403c334f-0b30-4123-99ff-8c85fe654595", "Queixa principal", [
      { valueString: data?.anamnese?.["Queixa principal"] },
    ]),
    createItem(
      "7219a846-a9ea-4054-fd41-f8b6415e8fba",
      "História da doença atual",
      [{ valueString: data?.anamnese?.["História da doença atual"] }]
    ),
    createItem(
      "34b02bf5-e48d-470a-bebd-bd62690d028d",
      "História patológica pregressa",
      [{ valueString: data?.anamnese?.["História patológica pregressa"] }]
    ),
    createItem(
      "3fc1c008-1d08-4e0d-de9a-57d4b86a3431",
      "História pessoal e social",
      [{ valueString: data?.anamnese?.["História pessoal e social"] }]
    ),
    createItem("f6a7b8d0-1a0a-4c5e-9c9e-1b0b2c3d4e5f", "História familiar", [
      { valueString: data?.anamnese?.["História familiar"] },
    ]),
  ].filter(Boolean);

  return items;
};

export const resultAuthorReferences = (data: any) => {
  const createItem = (linkId: string, text: string, answer: any[]) => ({
    linkId,
    text,
    answer,
  });

  const items = [
    createItem("healthcareDisplay", "Especialidade", [
      {
        valueString: `${data?.author?.healthcare}`,
      },
    ]),
    createItem("practitionerName", "Nome do Profissional", [
      {
        valueString: `${data?.author?.practitioner?.display}`,
      },
    ]),
  ].filter(Boolean);

  return items;
};

export const BuildQuestionnaireAnswers = (data: any) => {
  const AnamnesesAnswers = {
    linkId: "2376f9ec-1fc9-429e-8c31-fcd93ceae35c",
    text: "Anamnese",
    item: resultAnamnesis(data),
  };

  const ExamsPhysicalAnswers = {
    linkId: "73ecfd8a-a435-4fda-98d6-beb333cc0de4",
    text: "Exames Físicos",
    item: [
      {
        linkId: "19944617-03c7-4049-cf63-77ad8659b274",
        text: "Geral",
        item: [
          {
            linkId: "853f720f-f9ce-4f27-d3be-38267594b27d",
            text: "Cabeça e pescoço",
            answer: [
              {
                item: [
                  {
                    linkId: "2c4f6d0b-d0de-45d9-cf8a-d947947a7bfd",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Cabeça e pescoço"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Cabeça e pescoço"]
                ),
              },
            ],
          },
          {
            linkId: "fccafd57-db5d-4f90-8bbd-78b877292bc8",
            text: "Ap. Cardiovascular",
            answer: [
              {
                item: [
                  {
                    linkId: "e5d96c78-3fd7-4913-9be7-af050ac9c425",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Ap  Cardiovascular"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Ap  Cardiovascular"]
                ),
              },
            ],
          },
          {
            linkId: "7c91ff90-03b1-4ef6-8b7a-e3477cdd703c",
            text: "Ap. Abdominal",
            answer: [
              {
                item: [
                  {
                    linkId: "e72fcefa-1db0-4624-8740-b9ffc0516889",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Ap  Abdominal"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Ap  Abdominal"]
                ),
              },
            ],
          },
          {
            linkId: "cadce513-e0a1-46e8-c47e-8d4fba8ff771",
            text: "Ap. Respiratório",
            answer: [
              {
                item: [
                  {
                    linkId: "5b8271f2-44c3-4ba1-a969-643bdade340a",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Ap  Respiratório"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Ap  Respiratório"]
                ),
              },
            ],
          },
          {
            linkId: "37455c80-481a-457e-8675-3fe0353de8df",
            text: "Ap. urinário",
            answer: [
              {
                item: [
                  {
                    linkId: "c7c06a92-5c84-451f-e378-ce8ba4a971b4",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Ap  urinário"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Ap  urinário"]
                ),
              },
            ],
          },
          {
            linkId: "0b8b3d59-515a-4a39-831d-4fe3faca072f",
            text: "Osteoarticular",
            answer: [
              {
                item: [
                  {
                    linkId: "1dffe38a-4548-4255-8144-d49f7e28dfaa",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Osteoarticular"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Osteoarticular"]
                ),
              },
            ],
          },
          {
            linkId: "c5bf4f53-d880-4708-d4b8-300b2bf9db83",
            text: "Pele e fâneros",
            answer: [
              {
                item: [
                  {
                    linkId: "4fe361d6-06f1-4240-8b3a-bb51f49049a9",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Pele e fâneros"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Pele e fâneros"]
                ),
              },
            ],
          },
          {
            linkId: "bc2c0bbf-546a-439f-81f9-f95b09727f10",
            text: "Extremidades",
            answer: [
              {
                item: [
                  {
                    linkId: "7703f6de-cb90-4856-819d-69a50971ef6b",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Extremidades"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Extremidades"]
                ),
              },
            ],
          },
          {
            linkId: "f1d096b0-d911-4c9d-aecf-0e837bbd3384",
            text: "Outros achados",
            answer: [
              {
                item: [
                  {
                    linkId: "aa2b7845-e451-4af6-8d69-27f5b1b3ee07",
                    text: "Nome e descrição",
                    answer: [
                      {
                        valueString: `${data?.exams?.geral?.["Outros achados"]?.["Nome e descrição"]}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.geral?.["Outros achados"]
                ),
              },
            ],
          },
        ],
      },
      {
        linkId: "dd03e246-6fdb-4086-d36b-31528c560164",
        text: "Neurológico",
        item: [
          {
            linkId: "14eaad3e-5ca9-4360-8121-1043a3670095",
            text: "Estado Mental",
            answer: [
              {
                item: [
                  {
                    linkId: "5e34edf7-9f97-4f52-8c0c-e9188a285cf3",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Estado Mental"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Estado Mental"]
                ),
              },
            ],
          },
          {
            linkId: "3772d2ef-a875-415e-a894-0db684491d73",
            text: "Funções corticais superiores",
            answer: [
              {
                item: [
                  {
                    linkId: "28d2c43e-d655-49f5-837e-d639354e6756",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Funções corticais superiores"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Funções corticais superiores"]
                ),
              },
            ],
          },
          {
            linkId: "06b64474-a5a1-4554-85c4-6c27be215a10",
            text: "Marcha",
            answer: [
              {
                item: [
                  {
                    linkId: "e60fe499-5d1f-483a-937f-9b595f481f4e",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Marcha"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Marcha"]
                ),
              },
            ],
          },
          {
            linkId: "65bcc60a-dd1a-40dc-f79d-4263b45a0553",
            text: "Nervos Cranianos",
            answer: [
              {
                item: [
                  {
                    linkId: "223c9106-efbd-418e-b286-f1f612743cf3",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Nervos Cranianos"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Nervos Cranianos"]
                ),
              },
            ],
          },
          {
            linkId: "10225033-3a7a-4705-85f4-20faabe3ecd9",
            text: "Motricidade",
            answer: [
              {
                item: [
                  {
                    linkId: "fc6d2ed4-1bc2-4989-8f73-24e49aa34c3a",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Motricidade"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Motricidade"]
                ),
              },
            ],
          },
          {
            linkId: "260cb1ef-0fa3-458f-9f8d-96f2c5ca3a9a",
            text: "Reflexos",
            answer: [
              {
                item: [
                  {
                    linkId: "b157fdc4-f1f7-4f54-9bfa-3583c01b4e42",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Reflexos"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Reflexos"]
                ),
              },
            ],
          },
          {
            linkId: "02db7f06-4ebf-4243-cd47-d043aa01fbc5",
            text: "Cerebelares",
            answer: [
              {
                item: [
                  {
                    linkId: "39ea287e-395e-4fed-ec68-61068a327468",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Cerebelares"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Cerebelares"]
                ),
              },
            ],
          },
          {
            linkId: "3013231f-cfde-43e4-b951-0bb36ff0d615",
            text: "Sensorial",
            answer: [
              {
                item: [
                  {
                    linkId: "2155f79c-9742-4267-ea84-beedac2e5a8e",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Sensorial"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Sensorial"]
                ),
              },
            ],
          },
          {
            linkId: "7c962f44-0899-46f1-9d42-96c0cd5449b0",
            text: "SNA",
            answer: [
              {
                item: [
                  {
                    linkId: "17380f92-5e29-4b64-8b2e-325438bace92",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["SNA"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["SNA"]
                ),
              },
            ],
          },
          {
            linkId: "1a7ffda9-5c26-4f12-86ff-6684774a190c",
            text: "Geral",
            answer: [
              {
                item: [
                  {
                    linkId: "cc8394d4-ce40-4a39-92a2-71f2570a9755",
                    text: "Se alterado",
                    answer: [
                      {
                        valueString: `${data?.exams?.neurologico?.["Geral"]?.alterado}`,
                      },
                    ],
                  },
                ],
                valueCoding: resultExamPhysical(
                  data?.exams?.neurologico?.["Geral"]
                ),
              },
            ],
          },
        ],
      },
    ],
  };

  const ConductsAnswers = {
    linkId: "38adde2c-f083-435f-8a1f-fc9cc6fa740a",
    text: "Condutas",
    item: [...resultConducts(data?.New_Conduta, data?.CondutasPassadas)],
  };

  const ExamsResultsAnswers = {
    linkId: "0f1542f5-101c-49d7-e131-a6ca99f402dc",
    text: "Resultado de exames",
    item: [resultExams(data?.newExames, data?.pastExames)],
  };

  const ProblemListAnswers = {
    linkId: "f075e9cc-c1c4-44ac-db18-035cbc5453d4",
    text: "Lista de Problemas",
    item: [...resultProblems(data?.New_Problema, data?.ProblemasPassados)],
  };

  const TreatmentsPlansAnswers = {
    linkId: "4e1679c2-3e1d-444d-d438-28bdbeb1e0ee",
    text: "Planos de tratamento",
    item: [...resultTreatmentPlans(data?.newPlan, data?.CarePlans)],
  };

  const DiagnosticsAnswers = {
    linkId: "9643300d-7320-4935-80a0-cf5ce1369b80",
    text: "Diagnósticos",
    item: [
      {
        linkId: "f60c0380-3239-436a-9b4f-32f1eeda5ffb",
        text: "Diagnóstico",
        item: [
          {
            linkId: "70331526-9ba6-458a-a6ef-c5f951beea4e",
            text: "CID-10 Diagnóstico",
          },
          {
            linkId: "6105f0dd-a7eb-4866-81dd-1ed0c643ad85",
            text: "Status",
          },
        ],
      },
    ],
  };

  const TreatmentsAnswers = {
    linkId: "a3597d9a-cbc1-4e11-b9b8-ea303f2a2d7e",
    text: "Tratamentos",
    item: [
      {
        linkId: "8c21644f-567a-439d-db5b-2c2d342c63b2",
        text: "Tratamento",
        item: [
          {
            linkId: "82fde5de-9151-402c-e6f1-2a28db055a40",
            text: "Nome ",
          },
          {
            linkId: "dd3a7b57-5106-4a62-8ae4-6cd1b96b533a",
            text: "Tipo de tratamento",
          },
          {
            linkId: "13b5ee6b-9953-44f1-9e2c-45a8addf0e06",
            text: "Status",
          },
          {
            linkId: "95d7e91b-460a-438f-8d95-dbe4568f4741",
            text: "Uso\n",
          },
        ],
      },
    ],
  };

  const AuthorReferencesAnswers = {
    linkId: "displayReferences",
    text: "Referências do atendimento",
    item: [...resultAuthorReferences(data)],
  };

  return [
    AnamnesesAnswers,
    ExamsPhysicalAnswers,
    ConductsAnswers,
    ExamsResultsAnswers,
    ProblemListAnswers,
    TreatmentsPlansAnswers,
    AuthorReferencesAnswers,
  ];
};

export const BuildQuestionnaireResponse = ({
  questionnaireRef,
  status,
  patient,
  encounter,
  practitioner,
  answer,
  protocols,
}: {
  questionnaireRef: string;
  status:
    | "booked"
    | "completed"
    | "entered-in-error"
    | "in-progress"
    | "stopped"
    | "unknown";
  patient: string;
  encounter: string;
  practitioner: string;
  answer: any[];
  protocols: any[];
}) => {
  const QuestionnaireResponse = {
    resourceType: "QuestionnaireResponse",
    questionnaire: `Questionnaire/${questionnaireRef}`,
    status: status,
    authored: new Date(),
    author: {
      reference: `Practitioner/${oids.cpf}-${practitioner}`,
    },
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patient}`,
    },
    encounter: { reference: `Encounter/${encounter}` },
    item: answer,
    ...(protocols?.length && {
      extension: [
        {
          url: "https://fluxmed.com.br/fhir/StructureDefinition/appliedprotocol",
          valueReference: protocols?.map((e: any) => {
            return {
              reference: `QuestionnaireResponse/${e?.reference}`,
              display: e?.display,
            };
          }),
        },
      ],
    }),
  };

  return QuestionnaireResponse;
};
