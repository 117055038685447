import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { IFormInput } from "./types";
import { GetSchedule } from "../../services/fhir";

import { ServiceFilters } from "./components/ServiceFilters";
import { DisponibilityCard } from "./components/DisponibilityCard";

import { NoDataWarning } from "../../components/common";
import { SearchFields } from "../../components/schedule/SearchFields";
import { PrivateMainLayout } from "../../components/layout";
import { useDatetime } from "../../utils/useDatetime";
import { isMobile } from "react-device-detect";
import { FiltersButtonMobile } from "./components/FiltersButtonMobile";
import { useFhirData } from "../../utils/useFhirData";

export function SchedulingSearch() {
  const { state } = useLocation();
  const { formatDateToISO } = useDatetime;
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<any>([]);
  const [location, setLocation] = useState<any>([]);

  const defaultValues: IFormInput = {
    serviceModel: undefined,
    state: undefined,
    city: undefined,
    date: dayjs(),
    healthcareService: state?.healthcareService,
    organization: state?.organization,
    practitioner: state?.practitioner,
  };

  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, watch, getValues, setValue, resetField } =
    methods;

  const watchModel = watch("serviceModel");

  function isExtensionVirtualService(extension: fhir5.Extension) {
    return (
      extension.url ===
        "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
      extension.valueBoolean === true
    );
  }

  function isVirtualService(location: fhir5.Location) {
    return !!location?.extension?.find(isExtensionVirtualService);
  }

  async function fetchResponse() {
    const params: { [key: string]: string | undefined } = {
      serviceModel: getValues("serviceModel.value") || undefined,
      // state: getValues("state.value") || undefined,
      // city: getValues("city.value") || undefined,
      startDate: formatDateToISO(getValues("date") || dayjs()),
      specialty: getValues("healthcareService.label") || undefined,
      practitioner: getValues("practitioner.value") || undefined,
      organization: getValues("organization.value") || undefined,
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    const fetchSchedule = await GetSchedule({
      ...params,
      includeLocation: true,
      active: true,
    });
    const filterSchedules = fetchSchedule?.entry?.filter(
      (el: fhir5.BundleEntry<fhir5.Resource>) =>
        el?.resource?.resourceType === "Schedule"
    );
    const filterLocations = fetchSchedule?.entry?.filter(
      (el: fhir5.BundleEntry<fhir5.Resource>) =>
        el?.resource?.resourceType === "Location"
    );

    setSchedules(filterSchedules);
    setLocation(filterLocations);
  }

  useEffect(() => {
    fetchResponse();
  }, []);

  return (
    <>
      <PrivateMainLayout
        noSidebar={isMobile}
        mobileView={isMobile}
        noPadding={isMobile}
      >
        <Stack
          width="100%"
          height={isMobile ? window.innerHeight - 110 : "100%"}
          spacing={2}
          sx={{ backgroundColor: isMobile ? "neutral0.main" : "" }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100vw",
              backgroundColor: "neutral0.main",
              borderRadius: isMobile ? "0px" : "20px",
              mb: isMobile ? 0 : 2,
              padding: isMobile ? "30px 20px 10px" : "24px 40px",
            }}
          >
            <Typography
              sx={{
                typography: { sm: "body1", md: "h4" },
                textAlign: { sm: "start", md: "center" },
              }}
              fontWeight={600}
              mb={3}
            >
              {isMobile ? "O que você precisa hoje?" : "Agendar uma consulta"}
            </Typography>
            <SearchFields
              resetField={resetField}
              onSubmit={fetchResponse}
              control={control}
              watch={watch}
              handleSubmit={handleSubmit}
            />
            {/* {isMobile && (
              <FiltersButtonMobile control={control} setValue={setValue} />
            )} */}
          </Box>

          <Box
            sx={{
              marginTop: { sm: "-20px", md: "0px" },
              width: "100%",
              backgroundColor: "neutral0.main",
              borderRadius: isMobile ? "0px" : "30px",
              mb: 2,
              padding: isMobile ? "0px 20px " : "24px 40px",
              flex: 1,
              overflow: "auto",
              maxHeight: { xs: "375px", sm: "700px" },
            }}
          >
            {!isMobile && (
              <ServiceFilters control={control} onChange={fetchResponse} />
            )}
            {location ? (
              location
                ?.filter((loc: any) => {
                  const organizationFilter =
                    !loc?.resource?.managingOrganization?.reference?.includes(
                      "2.16.76.1.3.3-05490544000100"
                    );
                  const filter =
                    watchModel?.value === "virtualService"
                      ? isVirtualService(loc?.resource)
                      : !isVirtualService(loc?.resource);
                  const virtualServiceFilter = watchModel?.value
                    ? filter
                    : true;

                  return organizationFilter && virtualServiceFilter;
                })
                ?.map((el: any) => {
                  const auxSchedules = schedules?.filter((schedule: any) => {
                    const scheduleLocation = useFhirData.findActor(
                      schedule?.resource?.actor,
                      "Location"
                    );

                    return scheduleLocation?.reference.includes(
                      el?.resource?.id
                    );
                  });

                  return (
                    <DisponibilityCard
                      location={el?.resource}
                      schedules={auxSchedules}
                      startDate={formatDateToISO(
                        dayjs(getValues("date")) || dayjs()
                      )}
                    />
                  );
                })
            ) : (
              <Stack>
                <NoDataWarning />
              </Stack>
            )}
          </Box>
        </Stack>
      </PrivateMainLayout>
    </>
  );
}
