import { Fab, Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { Add, ArrowBack, ArrowForward, Done } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import { useSessionContext } from "../../../../contexts/SessionContext";
import { PostQuestionnaireResponse } from "../../../../services/fhir/patientsummary/questionnaireResponse/PostQuestionnaireResponse";
import {
  BuildQuestionnaireAnswers,
  BuildQuestionnaireResponse,
} from "./BuildQuestionnaireResponse";
import { endpoints, oids } from "../../../../configs/Settings";
import { QuickActionsDialog } from "../QuickActionsDialog";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";
import {
  PatchAppointmentStatus,
  PostMedicationRequest,
} from "../../../../services/fhir";
import { PostServiceRequest } from "../../../../services/fhir/patientsummary/serviceRequest/PostServiceRequest";
import { useFhirData } from "../../../../utils/useFhirData";
import { usePractitionerRoleContext } from "../../../../contexts/PractitionerRoleContext";
import { PrimaryButton, SecondaryButton } from "../../../../components/common";
import { WarningDocumentsDialog } from "./components/WarningDocumentsDialog";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { PostDocumentReference } from "../../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { useClinicalRegisterContext } from "../../../../contexts/ClinicalRegisterContext";
import { GetRelatedPerson } from "../../../../services/fhir/relatedperson/GetRelatedPerson";
import { VerifyResponsibleDialog } from "../../../../components/schedule/VerifyResponsibleDialog";
import { ShortCutsViewer } from "../StepArea/components/ShortCutsViewer";
import { PostObservation } from "../../../../services/fhir/observation/PostObservation";
import { toast } from "react-toastify";
import { PatchEncounterStatus } from "../../../../services/fhir/encounter/PatchEncounterStatus";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { PutQuestionnaireResponse } from "../../../../services/fhir/patientsummary/questionnaireResponse/PutQuestionnaireResponse";
import { PatchStatusObservation } from "../../../../services/fhir/observation/PatchObservation";

export function Footer() {
  const methods = useForm();
  const { control } = methods;
  const {
    activeStep,
    openDialog,
    type,
    setOpenDialog,
    setType,
    handleSubmit,
    appointment,
    setActiveStep,
    clearRegisterData,
  } = useMedicalCareContext();

  const { questionnaireDefualt } = useQuestionnaireContext();

  const { setInProgress } = useClinicalRegisterContext();
  const { selectedInterventions, base64, appliedProtocol } =
    useInterventionsContext();

  const { userPractitioner, userPractitionerRole, userSpecialties } =
    usePractitionerRoleContext();
  const { organization } = useOrganizationContext();

  const { user } = useSessionContext();
  const { state } = useLocation();
  const [warningSignature, setWarningSignature] = useState(false);
  const navigate = useNavigate();

  const references = state?.encounterReference;

  const healthcare = useFhirData?.findParticipant(
    appointment?.participant,
    "HealthcareService"
  )?.display;
  const servicePratice = userSpecialties?.find(
    (el: any) => el.coding?.[0]?.display === healthcare
  );
  const crm = userPractitionerRole?.identifier
    ?.find((id: any) => id.system === "crm")
    ?.value?.split("/")?.[1];

  const crmValue = crm?.slice(2);
  const crmUf = crm?.slice(0, 2);
  const docReferenceData = {
    organization: {
      id: organization?.id,
      alias: organization?.alias?.[0],
      name: organization?.name,
    },
    practitioner: {
      id: userPractitioner?.id,
      name: userPractitioner?.name?.[0]?.text,
    },
    servicePratice: {
      display: servicePratice?.coding?.[0]?.display,
      code: servicePratice?.coding?.[0]?.code,
      system: "https://fluxmed.com.br/fhir/CodeSystem/CFMEspecialidadeMedica",
    },
    time: {
      start: appointment?.start,
      end: appointment?.end,
    },
  };

  const [loading, setLoading] = useState<boolean>(false);

  const handlePrescription = async () => {
    const contained = [
      {
        resourceType: "Practitioner",
        id: "practitioner-1",
        identifier: [
          {
            ...(crmUf &&
              crmValue && {
                extension: [
                  {
                    url: "http://ehrrunner.com/fhir/StructureDefinition/BRJurisdicaoOrgaoEmissor-1.0",
                    valueCodeableConcept: {
                      coding: [
                        {
                          system:
                            "http://ehrrunner.com/fhir/CodeSystem/BRUnidadeFederacao-1.0",
                          code: crmUf,
                        },
                      ],
                    },
                  },
                ],
              }),

            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "MD",
                },
              ],
            },
            value: crmValue,
            assigner: {
              display: "CONSELHO REGIONAL DE MEDICINA (CRM)",
            },
          },
          {
            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "TAX",
                },
              ],
            },
            value: user?.username,
          },
        ],
        name: [
          {
            text: user?.name,
          },
        ],
      },
    ];

    const simpleBase64 = base64
      ?.find((el: any) => el["simple"])
      ?.["simple"]?.base64?.split(",")?.[1];

    const serviceBase64 = base64
      ?.find((el: any) => el["exam"])
      ?.["exam"]?.base64?.split(",")?.[1];

    const procedureBase64 = base64
      ?.find((el: any) => el["procedure"])
      ?.["procedure"]?.base64?.split(",")?.[1];

    const filterSpecial = base64?.filter((el: any, index: number) => {
      const labelTypeFilter = Object.keys(el)?.[0];
      return el?.[`${labelTypeFilter}`]?.type?.includes("special");
    });

    const notSignSimple = selectedInterventions?.medicationSimple?.filter(
      (el: any) => !el?.sign
    );
    const notSignSpecial = selectedInterventions?.medicationSpecial?.filter(
      (el: any) => !el?.sign
    );

    const notSignService = selectedInterventions?.service?.filter(
      (el: any) => !el?.sign
    );

    const notSignProcedure = selectedInterventions?.procedure?.filter(
      (el: any) => !el?.sign
    );

    const notSignCert = selectedInterventions?.certificates?.filter(
      (el: any) => !el?.sign
    );

    if (references)
      try {
        if (notSignSimple.length) {
          const simpleResponse = await PostMedicationRequest(
            notSignSimple,
            references
          );
          const auxSimpleBasedOn = simpleResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
              <fhir:reference value="${
                el.response?.location?.split("/_history")?.[0]
              }"/>
              </fhir:basedOn>
              `
            )
            ?.join("");

          if (simpleBase64 && auxSimpleBasedOn)
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64: simpleBase64,
                codeValue: "REC_SIM",
                codeDisplay: "Receituário Simples",
                categoryValue: "prescricao",
                categoryDisplay: "Receituário",
              },
              references,
              auxSimpleBasedOn
            );
        }

        if (notSignSpecial?.length) {
          notSignSpecial?.map(async (special: any, index: number) => {
            const specialBase64 = base64
              ?.find((el: any) => el[`special-${index}`])
              ?.[`special-${index}`]?.base64?.split(",")?.[1];

            const specialResponse = await PostMedicationRequest(
              [special],
              references
            );

            const auxMedicalBasedOn = specialResponse?.entry
              ?.map(
                (el: any) =>
                  `<fhir:basedOn>
          <fhir:reference value="${
            el.response?.location?.split("/_history")?.[0]
          }"/>
          </fhir:basedOn>
          `
              )
              ?.join("");
            if (specialBase64 && auxMedicalBasedOn) {
              await PostDocumentReference(
                {
                  ...docReferenceData,
                  base64: specialBase64,
                  codeValue: "REC_ESP",
                  codeDisplay: "Receituário Especial",
                  categoryValue: "prescricao",
                  categoryDisplay: "Receituário",
                },
                references,
                auxMedicalBasedOn
              );
            }
            return;
          });
        }

        if (notSignService?.length) {
          const serviceResponse = await PostServiceRequest(
            notSignService,
            references,
            contained
          );

          const auxServiceBasedOn = serviceResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");

          if (serviceBase64 && auxServiceBasedOn) {
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64: serviceBase64,
                codeValue: "SERVICE_REQUEST",
                codeDisplay: "Solicitação de exame",
                categoryValue: "service-request",
                categoryDisplay: "Solicitação",
              },
              references,
              auxServiceBasedOn
            );
          }
        }

        if (notSignProcedure?.length) {
          const procedureResponse = await PostServiceRequest(
            notSignProcedure,
            references,
            contained
          );

          const auxProcedureBasedOn = procedureResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");

          if (procedureBase64 && auxProcedureBasedOn) {
            await PostDocumentReference(
              {
                ...docReferenceData,
                base64: procedureBase64,
                codeValue: "SERVICE_REQUEST",
                codeDisplay: "Procedimento",
                categoryValue: "service-request",
                categoryDisplay: "Procedimento",
              },
              references,
              auxProcedureBasedOn
            );
          }
        }
        if (notSignCert?.length) {
          notSignCert?.map(async (certificates: any, index: number) => {
            const certificateBase64 = base64
              ?.find((el: any) => el[`certificate-${index}`])
              ?.[`certificate-${index}`]?.base64?.split(",")?.[1];

            return await PostDocumentReference(
              {
                ...docReferenceData,
                base64: certificateBase64,
                codeValue: "AT",
                codeDisplay: "Atestado",
                categoryValue: "atestado",
                categoryDisplay: "Atestado Médico",
              },
              references,
              null
            );
          });
        }
      } catch (err) {
        console.log("err", err);
      }
  };

  const [verifyDialog, setVerifyDialog] = useState<boolean>(false);
  const [related, setRelated] = useState<any>([]);

  async function fetchRelated() {
    const relatedResponse = await GetRelatedPerson({
      patient: `${oids.cpf}-${state?.encounterReference?.subject}`,
    }).then((response) => response?.data?.entry);
    const verifyRelated = relatedResponse?.filter(
      (el: any) =>
        el?.resource?.identifier &&
        !el?.resource?.active &&
        el?.resource?.period?.start
    );
    if (verifyRelated?.length) setRelated(verifyRelated);
    else setRelated([]);
    return verifyRelated;
  }

  const { summaryData } = useMedicalCareContext();
  const observationsToPatch = useFhirData
    .extractPatientSummaryResource(summaryData, "Observation")?.[0]
    ?.entry?.filter((observation: any) =>
      observation?.resource?.encounter?.reference?.includes(
        state?.encounterReference?.id
      )
    );

  const onSubmit = async (formData: any) => {
    const verifyRelated = await fetchRelated();

    if (verifyRelated?.length && !verifyDialog) {
      setVerifyDialog(true);
      return;
    }

    if (
      !warningSignature &&
      selectedInterventions?.all.length &&
      selectedInterventions?.all.filter((el: any) => !el.sign)?.length
    ) {
      setActiveStep(2);
      setWarningSignature(true);
      return;
    }

    setLoading(true);

    if (observationsToPatch?.length) {
      observationsToPatch?.map(async (observation: any) => {
        try {
          await PatchStatusObservation({
            observationId: observation?.resource?.id,
            status: "entered-in-error",
          });
        } catch (err) {
          console.log("err", err);
        }
      });
    }

    if (formData?.Anthropometric) {
      const cleanAnthropometrics = Object.keys(formData?.Anthropometric).filter(
        (key) => {
          const value = formData?.Anthropometric?.[key];
          if (key === "pressure") {
            const { sistolic, diastolic } = value;
            return (
              sistolic !== undefined &&
              sistolic !== "" &&
              diastolic !== undefined &&
              diastolic !== ""
            );
          }
          if (key === "smoking" || key === "breastfeeding") {
            if (value) {
              const { value: fieldValue } = value;
              return fieldValue !== "" || fieldValue === undefined;
            }
            return false;
          }
          return (
            value !== undefined &&
            value !== "" &&
            value !== " " &&
            value !== null
          );
        }
      );

      cleanAnthropometrics?.map(async (el: any) => {
        try {
          await PostObservation(
            {
              ...formData.Anthropometric,
              encounterID: references?.id,
              patientID: references?.subject,
            },
            el
          );
        } catch (err) {
          console.log("err", err);
        }
      });
    }

    try {
      const practitioner = useFhirData.findParticipant(
        state?.encounterReference.participant,
        "Practitioner"
      );

      formData.author = {
        healthcare,
        practitioner,
      };

      const answers = BuildQuestionnaireAnswers(formData);

      const questionnaireResponse: any = BuildQuestionnaireResponse({
        answer: answers,
        patient: state?.encounterReference?.subject,
        practitioner: user?.username,
        encounter: state?.encounterReference?.id,
        questionnaireRef: endpoints.QUESTIONNARIE_ID,
        status: "completed",
        protocols: appliedProtocol,
      });

      const response = questionnaireDefualt?.resource?.id
        ? await PutQuestionnaireResponse(
            {
              ...questionnaireResponse,
              id: questionnaireDefualt?.resource?.id,
              authored: new Date(appointment?.start),
              extension: questionnaireDefualt?.resource?.extension,
            },
            questionnaireDefualt?.resource?.id
          )
        : await PostQuestionnaireResponse({
            ...questionnaireResponse,
            authored: new Date(appointment?.start),
          });
      await handlePrescription();
      if (response?.id && references?.appointmentId) {
        await PatchAppointmentStatus({
          appointmentId: references?.appointmentId,
          status: "fulfilled",
        });

        await PatchEncounterStatus({
          encounterId: references?.id,
          status: "completed",
        });
      }
      toast.success("Atendimento registrado com sucesso!");
      clearRegisterData();
      setInProgress("");
      setLoading(false);
      navigate("/");
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };
  return (
    <>
      <Stack
        height="fit-content"
        width="100%"
        direction="row"
        alignItems="center"
      >
        <ShortCutsViewer />

        <Stack direction="row" spacing={1} width="100%" pl={45}>
          {activeStep !== 2 && (
            <>
              <Fab
                variant="extended"
                onClick={() => {
                  setOpenDialog(true);
                  setType(0);
                }}
              >
                <Add sx={{ mr: 1 }} />
                Diagnóstico
              </Fab>

              <Fab
                variant="extended"
                onClick={() => {
                  setOpenDialog(true);
                  setType(1);
                }}
              >
                <Add sx={{ mr: 1 }} />
                Conduta
              </Fab>
              <Fab
                variant="extended"
                onClick={() => {
                  setOpenDialog(true);
                  setType(2);
                }}
              >
                <Add sx={{ mr: 1 }} />
                Problema
              </Fab>
            </>
          )}
        </Stack>
        <Stack width="30%" direction="row" spacing={2} justifyContent="center">
          {/* {activeStep !== 2 && (
            <SecondaryButton
              width="fit-cotent"
              height="42px"
              onClick={() => setActiveStep((prev: any) => prev + 1)}
            >
              Continuar
            </SecondaryButton>
          )} */}

          {activeStep !== 0 && (
            <SecondaryButton
              disabled={activeStep === 0}
              width="fit-cotent"
              height="42px"
              onClick={() => setActiveStep((prev: any) => prev - 1)}
            >
              <ArrowBack /> Voltar
            </SecondaryButton>
          )}

          {activeStep !== 2 ? (
            <PrimaryButton
              width="250px"
              height="42px"
              endIcon={<ArrowForward />}
              loading={loading}
              onClick={() => setActiveStep((prev: any) => prev + 1)}
            >
              Continuar
            </PrimaryButton>
          ) : (
            <PrimaryButton
              width="250px"
              height="42px"
              endIcon={<Done />}
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              Finalizar atendimento
            </PrimaryButton>
          )}
        </Stack>
      </Stack>

      {openDialog && (
        <QuickActionsDialog
          control={control}
          isOpen={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          type={type}
        />
      )}

      {verifyDialog && (
        <VerifyResponsibleDialog
          submit={handleSubmit(onSubmit)}
          handleClose={() => setVerifyDialog(false)}
          isOpen={true}
          loading={loading}
          setLoading={setLoading}
          dataAppointment={appointment}
          related={related}
          fetchRelated={fetchRelated}
          patientId={state?.encounterReference?.subject}
        />
      )}

      {warningSignature && (
        <WarningDocumentsDialog
          isOpen={warningSignature}
          onClose={() => setWarningSignature(false)}
          submit={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
}
