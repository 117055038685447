import {
  BookmarkAddRounded,
  Delete,
  Edit,
  ExpandLess,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { InterdrugsVerification } from "../../medicaldocumentation/components/prescription/InterdrugsVerification";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { NoDataWarning } from "../../../components/common";
import { InterventionsTags } from "./InterventionsTags";
import { useParams } from "react-router-dom";

export function ListaDeIntervenções({
  selectedInterventions,
  functions,
  setCreateDialog,
  expandedList,
  setExpandedList,
  appliedProtocol,
}: any) {
  const { id } = useParams();

  const { summaryData } = useMedicalCareContext();

  const auxMedication = selectedInterventions?.all?.filter(
    (item: any) => item.type === "medication"
  );

  const handleChange = () => {
    setExpandedList((prev: boolean) => !prev);
  };

  return (
    <Accordion
      // defaultExpanded={expandedList}
      expanded={expandedList}
      // onChange={handleChange}
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
      }}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          Intervenções selecionadas
          <Stack direction="row" alignItems="center">
            {selectedInterventions?.all?.length && id !== "createprotocol" ? (
              <Button
                sx={{
                  width: "fit-content",
                  textTransform: "none",
                }}
                startIcon={<BookmarkAddRounded />}
                onClick={() => setCreateDialog(true)}
              >
                Criar novo protocolo
              </Button>
            ) : (
              <></>
            )}
            <IconButton onClick={handleChange}>
              {expandedList ? <ExpandLess /> : <GridExpandMoreIcon />}
            </IconButton>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {appliedProtocol?.length ? (
          <Stack direction="row" spacing={2} mr={1} alignItems="center">
            <Typography
              variant="body1"
              color="neutral1000.main"
              fontWeight={500}
            >
              Protocolos aplicados:
            </Typography>
            {appliedProtocol?.map((e: any, index: number) => (
              <Chip
                key={index}
                label={e?.display} // Nome do profissional
                onDelete={() =>
                  functions.handleRemoveProtocol(index, e?.reference)
                } // Função de remover o filtro
                color="primary" // Cor do chip
                style={{ margin: "4px" }} // Espaçamento entre os chips
              />
            ))}
          </Stack>
        ) : (
          <></>
        )}

        {selectedInterventions?.all.length ? (
          <>
            {auxMedication?.length ? (
              <Stack width="100%" spacing={1} mb={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography> Tratamentos: </Typography>

                  <InterdrugsVerification
                    summary={summaryData}
                    itens={auxMedication}
                  />
                </Stack>

                {auxMedication?.map((el: any, index: number) => {
                  const auxIndex = selectedInterventions?.all.indexOf(el);
                  return (
                    <Stack direction="row" key={`${el?.name}-${index}`}>
                      <Stack
                        p={1}
                        gap={1}
                        sx={{
                          background: "#F1F3F9",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          <Typography
                            variant="body1"
                            color="neutral1000.main"
                            fontWeight={500}
                          >
                            {index + 1}. {el.name}
                          </Typography>
                          {el?.tag && <InterventionsTags type={el.tag} />}
                          <Typography variant="body1" color="neutral700.main">
                            {el?.inputValue?.presentation}
                          </Typography>
                        </Stack>

                        <Stack direction="row" gap={1}>
                          <Typography variant="body1" color="neutral700.main">
                            {el?.form}
                          </Typography>
                        </Stack>
                        {el?.note && (
                          <Stack direction="row" gap={1}>
                            <Typography
                              color="neutral1000.main"
                              fontWeight={400}
                              sx={{
                                maxWidth: "100%",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                              }}
                            >
                              Observação: {el?.note}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      {!el?.sign && (
                        <Stack
                          direction="row"
                          p={1}
                          sx={{ alignItems: "center" }}
                        >
                          <IconButton sx={{ color: "black" }}>
                            <Edit
                              fontSize="small"
                              onClick={() =>
                                functions.handleSelect(el, auxIndex)
                              }
                            />
                          </IconButton>
                          <IconButton
                            sx={{ color: "black" }}
                            onClick={() => functions.handleRemove(auxIndex)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            ) : (
              <></>
            )}
            {selectedInterventions?.service?.length ? (
              <Stack width="100%" spacing={1} mb={1}>
                <Typography> Exames: </Typography>
                {selectedInterventions?.service?.map(
                  (el: any, index: number) => {
                    const auxIndex = selectedInterventions?.all.indexOf(el);
                    return (
                      <Stack direction="row" key={`${el?.name}-${index}`}>
                        <Stack
                          p={1}
                          gap={1}
                          sx={{
                            background: "#F1F3F9",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          <Stack direction="row" gap={1}>
                            <Typography
                              variant="body1"
                              color="neutral1000.main"
                              fontWeight={500}
                            >
                              {index + 1}. {el?.name}
                            </Typography>
                            {el?.tag && <InterventionsTags type={el.tag} />}

                            <Typography variant="body1" color="neutral700.main">
                              Código TUSS: {el?.inputValue?.code}
                            </Typography>
                          </Stack>

                          <Stack direction="row" gap={1}>
                            <Typography variant="body1" color="neutral700.main">
                              {el?.condition?.label}{" "}
                              {!el?.condition?.name &&
                                el.condition?.value &&
                                `- CID10 ${el.condition?.value}`}
                            </Typography>
                          </Stack>

                          <Typography variant="body1" color="neutral700.main">
                            {el?.category?.label}
                          </Typography>
                          {el?.note && (
                            <Stack direction="row" gap={1}>
                              <Typography
                                color="neutral1000.main"
                                fontWeight={400}
                                sx={{
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                }}
                              >
                                Observação: {el?.note}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                        {!el?.sign && (
                          <Stack
                            direction="row"
                            p={1}
                            sx={{ alignItems: "center" }}
                          >
                            <IconButton sx={{ color: "black" }}>
                              <Edit
                                fontSize="small"
                                onClick={() =>
                                  functions.handleSelect(el, auxIndex)
                                }
                              />
                            </IconButton>
                            <IconButton
                              sx={{ color: "black" }}
                              onClick={() => functions.handleRemove(auxIndex)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Stack>
                        )}
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <></>
            )}
            {selectedInterventions.procedure?.length ? (
              <Stack width="100%" spacing={1} mb={1}>
                <Typography> Procedimentos: </Typography>
                {selectedInterventions.procedure?.map(
                  (el: any, index: number) => {
                    const auxIndex = selectedInterventions?.all?.indexOf(el);
                    return (
                      <Stack direction="row" key={`${el?.name}-${index}`}>
                        <Stack
                          p={1}
                          gap={1}
                          sx={{
                            background: "#F1F3F9",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          <Stack direction="row" gap={1}>
                            <Typography
                              variant="body1"
                              color="neutral1000.main"
                              fontWeight={500}
                            >
                              {index + 1}. {el?.name}
                            </Typography>
                            {el?.tag && <InterventionsTags type={el.tag} />}
                          </Stack>
                          {el?.note && (
                            <Stack direction="row" gap={1}>
                              <Typography
                                color="neutral1000.main"
                                fontWeight={400}
                                sx={{
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                }}
                              >
                                Observação: {el?.note}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                        {!el?.sign && (
                          <Stack
                            direction="row"
                            p={1}
                            sx={{ alignItems: "center" }}
                          >
                            <IconButton sx={{ color: "black" }}>
                              <Edit
                                fontSize="small"
                                onClick={() =>
                                  functions.handleSelect(el, auxIndex)
                                }
                              />
                            </IconButton>
                            <IconButton
                              sx={{ color: "black" }}
                              onClick={() => functions.handleRemove(auxIndex)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Stack>
                        )}
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <></>
            )}
            {selectedInterventions?.certificates?.length ? (
              <Stack width="100%" spacing={1} mb={1}>
                <Typography> Relatórios: </Typography>
                {selectedInterventions?.certificates?.map(
                  (el: any, index: number) => {
                    const auxIndex = selectedInterventions?.all?.indexOf(el);
                    return (
                      <Stack direction="row" key={`${el?.name}-${index}`}>
                        <Stack
                          p={1}
                          gap={1}
                          sx={{
                            background: "#F1F3F9",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          <Stack direction="row" gap={1}>
                            <Typography
                              variant="body1"
                              color="neutral1000.main"
                              fontWeight={500}
                            >
                              {index + 1}. {el?.name}
                            </Typography>
                            {el?.tag && <InterventionsTags type={el.tag} />}
                          </Stack>
                          {el?.note && (
                            <Stack direction="row" gap={1}>
                              <Typography
                                color="neutral1000.main"
                                fontWeight={400}
                                sx={{
                                  maxWidth: "100%",
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                }}
                              >
                                Observação: {el?.note}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>

                        {!el?.sign && (
                          <Stack
                            direction="row"
                            p={1}
                            sx={{ alignItems: "center" }}
                          >
                            <IconButton sx={{ color: "black" }}>
                              <Edit
                                fontSize="small"
                                onClick={() =>
                                  functions.handleSelect(el, auxIndex)
                                }
                              />
                            </IconButton>
                            <IconButton
                              sx={{ color: "black" }}
                              onClick={() => functions.handleRemove(auxIndex)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Stack>
                        )}
                      </Stack>
                    );
                  }
                )}
              </Stack>
            ) : (
              <></>
            )}
          </>
        ) : (
          <NoDataWarning message="Nenhuma intervenção foi selecionada" />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
