import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

export function TratamentoHistoryAccordion({ data, index }: any) {
  const status: any = {
    cancelled: "Cancelado",
    proposed: "Pendente",
    pending: "Pendente",
    arrived: "Confirmado",
    booked: "Confirmado",
    valid: "Ativo",
    expired: "Expirado",
    stopped: "Interrompido",
    active: "Ativo",
    draft: "Solicitado",
    completed: "Finalizado",
    result: "Realizado",
  };

  const statusColor: any = {
    cancelled: "Cancelado",
    proposed: "Pendente",
    pending: "Pendente",
    arrived: "Confirmado",
    booked: "Confirmado",
    valid: "Ativo",
    expired: "Expirado",
    stopped: "Interrompido",
    active: "neutral500.main",
    draft: "#DAA520",
    completed: "#3CB371",
    result: "Realizado",
  };

  const { loadingTreatments } = useMedicalCareContext();

  return (
    <>
      {loadingTreatments && index === 0 ? (
        <Box
          sx={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 2,
          }}
        >
          <Skeleton
            variant="text"
            width={"100%"}
            sx={{ fontSize: "1rem", mb: 2 }}
          />
          <Skeleton variant="text" width={"100%"} sx={{ fontSize: "1rem" }} />
          <Stack direction="row" spacing={12} sx={{ my: 2 }}>
            <Skeleton variant="rounded" width={"25%"} height={32} />
          </Stack>

          <Divider></Divider>
          <Stack direction="row" spacing={2}>
            <Skeleton
              variant="text"
              width={"75%"}
              sx={{ fontSize: "1.2rem" }}
            />
            <Skeleton variant="text" width={"25%"} sx={{ fontSize: "1rem" }} />
          </Stack>
        </Box>
      ) : (
        <>
          {" "}
          <Accordion
            defaultExpanded={true}
            expanded={true}
            sx={{
              boxShadow: "none",
              border: "1px solid #ccc",
              borderRadius: 1,
              borderColor: statusColor?.[data?.status],
            }}
          >
            <AccordionSummary aria-controls="panel1-content" id="panel1-header">
              <Stack spacing={1}>
                {data?.description && (
                  <Stack direction="row" width="100%" spacing={1}>
                    <Typography color={"neutral700.main"} fontWeight={500}>
                      {data?.description}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {data?.apresentação && (
                  <Stack direction="row" width="100%" spacing={1}>
                    <Typography fontWeight={500}>Apresentação </Typography>
                    <Typography>{data?.apresentação}</Typography>
                  </Stack>
                )}
              </Stack>
              <Stack spacing={1} mb={2}>
                {data?.dose && (
                  <Stack direction="row" width="100%" spacing={1}>
                    <Typography fontWeight={500}>Posologia: </Typography>
                    <Typography>{data?.dose}</Typography>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" justifyContent={"space-between"} mb={1}>
                <Stack direction="column">
                  <Typography color={"neutral700.main"} fontSize={12} mx={1}>
                    Status:
                  </Typography>
                  <Chip
                    label={status?.[data?.status]}
                    size="small"
                    variant="outlined"
                    sx={{
                      width: "auto",
                      fontWeight: 500,
                      fontSize: 12,
                      borderRadius: 2,
                      borderColor: statusColor?.[data?.status],
                    }}
                  />
                </Stack>
              </Stack>

              {data?.note ? (
                <Typography
                  style={{
                    backgroundColor: "#eeeeee",
                    color: "#757575",
                    padding: 8,
                    borderRadius: 8,
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {data?.note}
                </Typography>
              ) : (
                <></>
              )}
              {/* <Divider sx={{ my: 2 }}></Divider>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          alignItems="center"
          spacing={2}
        >
          {data?.displayInfo?.practitioner && (
            <Typography
              color="neutral700.main"
              sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
            >
              Dr. {data?.displayInfo?.practitioner}
            </Typography>
          )}

          {data?.displayInfo?.healthcare && (
            <Chip
              label={data?.displayInfo?.healthcare || "Sem especialidade"}
              size="small"
              sx={{
                color: data?.displayInfo?.healthcare
                  ? "neutral700.main"
                  : "neutral500.main",

                fontWeight: 500,
                backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
              }}
            />
          )}
        </Stack> */}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
}
