import { Box, Divider, Stack, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { PrimaryButton } from "../../../components/common";

import logo from "../../../assets/fluxmed-logo-horizontal.png";
import { Link } from "react-router-dom";
import { FormTextInput } from "../../../components/form";
import { useFormatter } from "../../../utils/useFormatter";
import { useRegex } from "../../../utils/useRegex";
import { isMobile } from "react-device-detect";
import {
  GetOrganizations,
  PutOrganization,
  Validate,
} from "../../../services/fhir";
import { oids } from "../../../configs/Settings";

interface IProps {
  documentBinary: any;
  handleRefetch: any;
  documentReference: any;
}

export function ValidateFields({
  documentBinary,
  handleRefetch,
  documentReference,
}: IProps) {
  const methods = useForm();
  const { handleSubmit, reset, control } = methods;

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  function downloadBase64File(base64: any) {
    if (isMobile) {
      const blob = base64ToBlob(documentBinary, "application/pdf");
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: `Documento-${documentBinary?.substring(0, 5)}.pdf`,
      };
      if (auxFile) {
        const link = document.createElement("a"); // Cria um elemento <a>
        link.href = auxFile?.fileUrl; // Define o URL do Blob
        link.download = auxFile?.fileName; // Define o nome do arquivo
        // document.body.appendChild(link); // Adiciona o link ao documento
        link.click(); // Simula o clique no link
        // document.body.removeChild(link); // Remove o link do documento
        // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
      } else {
        alert(
          "O arquivo ainda está sendo preparado, por favor tente novamente."
        );
      }
    }
  }

  const onSubmit = async (data: any) => {
    if (data?.cnpjValue) {
      try {
        try {
          const verifyOrganization = await GetOrganizations({
            orgIds: `${oids.cnpj}-${data?.cnpjValue?.replace(
              /[^a-zA-Z0-9]/g,
              ""
            )}`,
            applicationAuth: true,
          });

          if (verifyOrganization?.id) {
            handleRefetch(verifyOrganization?.id?.split("-")?.[1]);
          }
        } catch {
          const validation = await Validate(
            {
              // cpf: user.username,
              cnpj: `${data?.cnpjValue?.replace(/[^a-zA-Z0-9]/g, "")}`,
            },
            true
          )?.then((response: any) => response?.data);

          const createdOrganization = await PutOrganization(
            {
              ...validation,
              id: `${oids.cnpj}-${data?.cnpjValue?.replace(
                /[^a-zA-Z0-9]/g,
                ""
              )}`,
            },
            true
          );
          handleRefetch(createdOrganization?.id?.split("-")?.[1]);
        }
      } catch (err) {
        console.log("validateOrg", err);
      } finally {
        if (isMobile) {
          downloadBase64File(documentBinary);
        }
        reset();
      }
    }

    // setOpenValidationModal(false);
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <Box
        justifyContent="center"
        width="100%"
        height="fit-content"
        display={{ xs: "flex", lg: "none" }}
      >
        <img src={logo} alt="Logo fluxmed" width={150} height="auto" />
      </Box>
      <Divider sx={{ paddingY: 1 }} />
      <Typography variant="h5" textAlign="center" fontWeight={600}>
        Validação de documentos digitais
      </Typography>
      <Typography
        mt={1}
        variant="body1"
        color="neutral700.main"
        display={{ xs: "none", lg: "flex" }}
      >
        <Box>
          Aqui você vai validar o conteúdo do documento digital fornecido pelo
          paciente. Este documento foi assinado digitalmente com Certificados
          Digitais ICP-Brasil Caso receba o documento digital, você pode
          validá-lo em{" "}
          <Link to="https://validar.iti.gov.br/" target="_blank">
            https://validar.iti.gov.br/
          </Link>
        </Box>
      </Typography>
      {!isMobile && (
        <Box
          width="100%"
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: {
              xs: "400px",
              sm: "500px",
              md: "600px",
            },
          }}
        >
          <iframe
            title="View"
            src={`data:application/pdf;base64,${documentBinary}#zoom=page-fit`}
            width="100%"
            height="100%"
            // style={{ maxWidth: "700px" }}
          />
        </Box>
      )}

      <Stack width="100%" mt={2}>
        <Typography variant="body1">
          Colabore com a segurança dos dados do paciente, informe
          voluntariamente o CNPJ do estabelecimento que acessou esta informação.
        </Typography>
      </Stack>

      <FormTextInput
        label="Digite o CNPJ do estabelecimento"
        name="cnpjValue"
        mask={useFormatter.formatCNPJ}
        pattern={useRegex.cnpj}
        control={control}
      />

      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        mt={2}
      >
        <PrimaryButton
          width={{ xs: "100%", sm: "60%", md: "50%" }}
          height="42px"
          onClick={handleSubmit(onSubmit)}
        >
          Confirmar
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
