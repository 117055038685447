import { Stack } from "@mui/material";
import { FormAutoComplete } from "../form";

import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { ISelectOption } from "./interfaces";
import { GetValueSet } from "../../services/terminology";
import { HTTPResponseStatus } from "../../utils/enum";
import { ISpecialty } from "../../pages/professionalregister/Types";
import { GetPractitionerRole } from "../../services/fhir";
import { PrimaryButton } from "../common";
import { useFormatter } from "../../utils/useFormatter";
import { isMobile } from "react-device-detect";
import { useOrganizationContext } from "../../contexts/OrganizationContext";

const defaultValues: { [key: string]: ISelectOption | undefined } = {
  healthcareService: undefined,
  organization: undefined,
  practitioner: undefined,
};

interface IProps {
  onSubmit: any;
  control: any;
  watch: any;
  resetField: any;
  handleSubmit: Function;
}

export function SearchFields({
  onSubmit,
  control,
  watch,
  resetField,
  handleSubmit,
}: IProps) {
  const { state } = useLocation();

  const { organizationList } = useOrganizationContext();

  const { formatNameDisplay } = useFormatter;

  const isSelectOption = (el: any): el is ISelectOption => el.label && el.value;

  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [healthcareService, setHealthcareService] = useState<any>();
  const [practitionerOptions, setPractionerOptions] = useState<any>();

  if (state) {
    for (const [key, value] of Object.entries(state)) {
      if (defaultValues.hasOwnProperty(key) && isSelectOption(value)) {
        defaultValues[key] = value;
      }
    }
  }

  const watchHealthcareService = watch("healthcareService");
  const watchOrganization = watch("organization");

  const organizationOptions = organizationList
    ?.filter((e: any) => {
      return e?.resource?.id !== "2.16.76.1.3.3-05490544000100";
    })
    ?.map((org: any) => {
      return {
        value: org?.resource?.id,
        label:
          org?.resource?.alias?.[0] !== "null"
            ? org?.resource?.alias?.[0]
            : org?.resource?.name,
      };
    });

  useEffect(() => {
    async function asyncCall() {
      setLoading(true);
      const EspecialidadeMedicaValueSet = await GetValueSet({
        valueset: "EspecialidadeMedica",
      }).then((response) => {
        if (response?.status === HTTPResponseStatus.Success) {
          return response.data.expansion.contains;
        }
        return [];
      });
      setHealthcareService(
        EspecialidadeMedicaValueSet.map((item: ISpecialty) => ({
          value: item.code,
          label: item.display,
        }))
      );

      setLoading(false);
    }
    asyncCall();
  }, []);

  useEffect(() => {
    async function fetchPractitionerRole() {
      const practitionerRoleData = watchOrganization
        ? await GetPractitionerRole({
            organization: watchOrganization?.value,
          })
        : await GetPractitionerRole({
            includePractitioner: true,
          });

      const practitionerRole = practitionerRoleData?.entry?.filter(
        (e: any) =>
          e?.resource?.resourceType === "PractitionerRole" &&
          !e?.resource?.id?.includes("05490544000100")
      );

      const practitioner = practitionerRoleData?.entry?.filter(
        (e: any) => e?.resource?.resourceType === "Practitioner"
      );

      const filterPractitioners = practitioner?.filter((e: any) => {
        const auxRole = practitionerRole?.some((role: any) => {
          return role?.resource?.id?.includes(e?.resource?.id);
        });

        return auxRole;
      });

      const pracOptions = filterPractitioners?.map((el: any) => {
        const auxIdentifier = el?.resource?.id?.split("-")?.[1];
        const auxRoles = practitionerRole?.filter((role: any) => {
          const auxRole = role?.resource?.id?.includes(auxIdentifier);
          const isFromSelectedOrganization = watchOrganization
            ? role?.resource?.organization?.reference?.includes(
                watchOrganization?.value
              )
            : true;

          return auxRole && isFromSelectedOrganization;
        });

        const auxSpecialties = auxRoles
          ?.map((role: any) => {
            return role?.resource?.specialty;
          })
          ?.filter((e: any) => e?.length);

        const practitionerSpecialties = auxSpecialties?.flatMap(
          (item: any) => item
        );

        return {
          label: formatNameDisplay(el?.resource?.name?.[0]?.text),
          value: el?.resource?.id,
          specialties: practitionerSpecialties,
        };
      });

      const auxOptions = pracOptions?.filter((e: any) => {
        const hasSelectSpecialty = watchHealthcareService
          ? e?.specialties?.some(
              (sp: any) =>
                sp?.coding?.[0]?.code === watchHealthcareService?.value
            )
          : true;

        return hasSelectSpecialty;
      });

      setPractionerOptions(auxOptions);
    }
    if (organizationOptions) fetchPractitionerRole();
  }, [watchHealthcareService, formatNameDisplay, watchOrganization]);

  useEffect(() => {
    if (!practitionerOptions?.length) resetField("practitioner");
  }, [practitionerOptions]);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, lg: 2 }}
      width="100%"
      alignItems="center"
    >
      {isMobile && !id ? (
        <FormAutoComplete
          name="healthcareService"
          control={control}
          label="Serviço de saúde"
          loading={loading}
          selectOptions={healthcareService || []}
        />
      ) : (
        <>
          <FormAutoComplete
            name="healthcareService"
            control={control}
            label="Serviço de saúde"
            loading={loading}
            selectOptions={healthcareService || []}
          />

          <FormAutoComplete
            name="organization"
            control={control}
            label="Clínica"
            loading={false}
            selectOptions={organizationOptions || []}
          />
          <FormAutoComplete
            name="practitioner"
            control={control}
            label="Profissional"
            selectOptions={practitionerOptions || []}
          />
        </>
      )}

      <PrimaryButton
        width="100%"
        height="56px"
        onlyIcon={isMobile && <Search />}
        endIcon={!isMobile && <Search />}
        onClick={handleSubmit(onSubmit)}
      >
        {isMobile ? "" : "Buscar"}
      </PrimaryButton>
    </Stack>
  );
}
