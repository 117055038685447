import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../../components/form";
import { useState } from "react";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { toast } from "react-toastify";
import { APPC_CONSENT } from "../../../../services/appc";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { isMobile } from "react-device-detect";
import { APPC_DOC_CONSENT } from "../../../../services/appc/APPC_DOC_CONSENT";

export function RevokeConsentDialog({ setRefetch }: any) {
  const { user } = useSessionContext();
  const {
    revokeDialog,
    setRevokeDialog,
    subjectName,
    subjectId,
    resetStates,
    consentType,
    uuid,
  } = useConsentContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    resetStates();
    setRevokeDialog(false);
  };

  console.log(
    "uuid",
    subjectId?.split("1.3.6.1.4.1.54413.3.2.3.")?.[1]?.split(".10.")?.[0]
  );
  const handleConfirm = async () => {
    try {
      if (consentType === "cpf") {
        await APPC_CONSENT({
          rule: "Deny",
          patient_id: user.username,
          subject_id: [subjectId],
          cnes: null,
        });
      } else if (consentType === "cnpj") {
        await APPC_CONSENT({
          rule: "Deny",
          patient_id: user.username,
          subject_id: null,
          cnes: [subjectId],
        });
      } else {
        const idValue = subjectId?.split(".10.")?.[1]?.split(".2")?.[0];
        const organizationId = subjectId
          ?.split("1.3.6.1.4.1.54413.3.2.3.")?.[1]
          ?.split(".10.")?.[0];

        await APPC_DOC_CONSENT({
          rule: "Deny",
          patient_id: user.username,
          doc_id: uuid,
          idValue,
          cnes: organizationId,
        });
      }

      toast.success(`Consentimento removido com sucesso! `);
    } catch {
      toast.error(`Não foi possível finalizar a operação.`);
    } finally {
      setRefetch((prev: boolean) => !prev);
      handleClose();
    }
  };

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={revokeDialog}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">
        Remover Consentimento
        {consentType === "document" ? (
          <Typography variant="body1" color="neutral700.main">
            Atenção: ao confirmar essa ação o documento não poderá mais ser
            validado ou acessado por estabelecimentos de saúde.
          </Typography>
        ) : (
          <Typography variant="body1" color="neutral700.main">
            Atenção: ao confirmar essa ação <b> {subjectName} </b> não terá mais
            acesso a suas informações de saúde.
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleConfirm}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
