interface ProjectFeatures {
  [key: string]: () => {
    signin: boolean;
    signup: boolean;
    register: boolean;
    profile: boolean;
    profileClinic: boolean;
    home: boolean;
  };
}

function projectFeatures() {
  const projectFeatures: ProjectFeatures = {
    standard: () => ({
      signin: true,
      signup: true,
      profile: true,
      profileClinic: true,
      register: false,
      home: true,
    }),
    register: () => ({
      signin: true,
      signup: true,
      profile: false,
      profileClinic: false,
      register: true,
      home: false,
    }),
  };
  return projectFeatures[process.env.REACT_APP_PROJ_MODE || "standard"]();
}

export const currentFeatures = projectFeatures();

interface ISettings {
  XDSB_domain: any;
  principalDomain: any;
  token_api: any;
  portalConsumerKey: any;
  portalConsumerSecret: any;
  SIGNUP: any;
  REGISTER: any;
  recover: any;
  Scim2: any;
  TERMINOLOGY: any;
  Interdrugs: any;
  Brevo: any;
  BrevoApiKey: any;
  USERFHIR: any;
  ADMFHIR: any;
  WORKFLOWFHIR: any;
  CONSENT: any;
  MPIFHIR: any;
  APPC: any;
  MHD: any;
  EHRPOSTER: any;
  SCHEDULING: any;
  QUESTIONNARIE_ID: any;
  PROTOCOL_ID: any;
  DSIG: any;
  ViaCep: any;
}

export const endpoints: ISettings = {
  XDSB_domain: process.env.REACT_APP_ENDPOINT_XDSB_domain,
  principalDomain: process.env.REACT_APP_ENDPOINT_principalDomain,
  token_api: process.env.REACT_APP_ENDPOINT_token_api,
  portalConsumerKey: process.env.REACT_APP_PORTAL_CONSUMER_KEY,
  portalConsumerSecret: process.env.REACT_APP_PORTAL_CONSUMER_SECRET,
  SIGNUP: process.env.REACT_APP_ENDPOINT_SIGNUP,
  REGISTER: process.env.REACT_APP_ENDPOINT_REGISTER,
  Scim2: process.env.REACT_APP_ENDPOINT_Scim2,
  recover: process.env.REACT_APP_ENDPOINT_RECOVER,
  TERMINOLOGY: process.env.REACT_APP_ENDPOINT_TERMINOLOGY,
  Brevo: process.env.REACT_APP_ENDPOINT_BREVO_SMTP,
  Interdrugs: process.env.REACT_APP_ENDPOINT_MANOLE,
  BrevoApiKey: process.env.REACT_APP_ENDPOINT_BREVO_API_KEY,
  USERFHIR: process.env.REACT_APP_ENDPOINT_USERFHIR,
  ADMFHIR: process.env.REACT_APP_ENDPOINT_ADMFHIR,
  WORKFLOWFHIR: process.env.REACT_APP_ENDPOINT_WORKFLOW_FHIR,
  CONSENT: process.env.REACT_APP_ENDPOINT_CONSENT_api,
  MPIFHIR: process.env.REACT_APP_ENDPOINT_MPI_FHIR,
  APPC: process.env.REACT_APP_ENDPOINT_APPCFHIR,
  MHD: process.env.REACT_APP_ENDPOINT_MHD,
  EHRPOSTER: process.env.REACT_APP_ENDPOINT_POSTER,
  SCHEDULING: process.env.REACT_APP_ENDPOINT_SCHEDULING,
  QUESTIONNARIE_ID: process.env.REACT_APP_QUESTIONNAIRE_ID,
  PROTOCOL_ID: process.env.REACT_APP_PROTOCOL_ID,
  DSIG: process.env.REACT_APP_ENDPOINT_DSIG,
  ViaCep: process.env.REACT_APP_ENDPOINT_VIACEP,
};

export const docTypes = [
  {
    name: "Lab results",
    alias: "RE",
    codes: [
      "1.3.6.1.4.1.54413.1.1.2.3.1",
      "SHROP - COVID19 laboratory report.v1.0",
      "Lab results",
    ],
  },
  {
    name: "Discharge summary",
    alias: "SA",
    codes: ["SHROP - Discharge summary report.v1.0"],
  },
  {
    name: "Encounter (consultation) summary",
    alias: "RAC",
    codes: ["SHROP - Encounter summary report.v1.0"],
  },
  {
    name: "Notification COVID-19",
    alias: "NCSC",
    codes: ["SHROP - Notification COVID19.v1.0"],
  },
  {
    name: "Immunization",
    alias: "RI",
    codes: ["SHROP - Immunization report.v1.0", "RegistroImunobiológico_V3"],
  },
];

export const oids = {
  organization: "1.3.6.1.4.1.54413.1.2.2.1",
  cnpj: "2.16.76.1.3.3",
  cpf: "2.16.840.1.113883.13.237",
  cns: "2.16.840.1.113883.13.236",
  practitionerRoles: "1.3.6.1.4.1.54413.1.1.2.1",
  programHealthCareService: "1.3.6.1.4.1.54413.1.1.2.2",
  categoryHealthCareService: "1.3.6.1.4.1.54413.1.1.6.8",
  susAttendance: "1.3.6.1.4.1.54413.1.1.6.7",
  admType: "1.3.6.1.4.1.54413.1.1.6.6",
  legalNature: "1.3.6.1.4.1.54413.1.1.6.4",
  orgType: "1.3.6.1.4.1.54413.1.1.6.3",
  immunobiological: "1.3.6.1.4.1.54413.1.1.6.10",
  doseCovidVaccine: "1.3.6.1.4.1.54413.1.1.6.14",
  immunizationEffects: "1.3.6.1.4.1.54413.1.1.6.13",
  documentPdf: "1.3.6.1.4.1.54413.1.1.2.3.1",
  council: "2.16.840.1.113883.13.243",
};

export const roles = {
  Practitioner: "RL_PRACTITIONER",
  Manager: "RL_MANAGER",
  Owner: "RL_OWNER",
  Invited: "RL_INVITED",
};
