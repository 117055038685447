import { GenericTimeline } from "../GenericTimeline";
import { Stack } from "@mui/material";

import { ExamesHistoryAccordion } from "./ExamesHistoryAccordion";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";

export function ExamesHistory({ filter }: any) {
  const examType = filter === 0 ? "Geral" : "Neurológico";

  const { historyValues } = useQuestionnaireContext();
  const responseEntries = historyValues?.map((el: any) => el?.resource);
  const examsResponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item
      ?.filter((e: any) => e?.text === "Exames Físicos")?.[0]
      ?.item?.find((el: any) => {
        return el.text === examType;
      });
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { awnserDate, filterItens, displayInfo };
  });

  return (
    <Stack>
      <GenericTimeline
        data={examsResponses}
        component={(data: any, index: number) => (
          <ExamesHistoryAccordion data={data} index={index} />
        )}
      />
    </Stack>
  );
}
