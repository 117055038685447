import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { NoDataWarning } from "../../../components/common";
import dayjs from "dayjs";
import { GetPaymentNotice } from "../../../services/fhir/financial/GetPaymentNotice";
import { useContractContext } from "../../../contexts/ContractContext";

export function PaymentPanel() {
  const { currentContract, inactiveContracts } = useContractContext();
  const [paymentResponse, setPaymentResponse] = useState<any>();

  const inativeIds =
    inactiveContracts?.map((el: any) => `Contract/${el?.resource?.id}`) || [];

  const ids = [
    ...inativeIds,
    `Contract/${currentContract?.[0]?.resource?.id}`,
  ]?.filter((el: any) => el?.split("/")?.[1]);

  const [loading, setLoading] = useState<boolean>(false);
  const columnsPayment = [
    { column: "Data", rowName: "paymentDate" },
    { column: "Valor", rowName: "amount" },
  ];
  const rowsPayment = paymentResponse
    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a?.resource?.paymentDate);
      const dateB = dayjs(b?.resource?.paymentDate);
      return dateB.diff(dateA);
    })
    ?.map((el: any) => {
      const payment = el?.resource?.paymentDate;
      return {
        status: el?.resource?.status,
        paymentDate: payment
          ? dayjs(el?.resource?.paymentDate).format("DD/MM/YYYY")
          : "-",
        amount:
          el?.resource?.amount?.value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }) || "-",
      };
    });

  useEffect(() => {
    try {
      if (ids) {
        const detailsPlan = async () => {
          setLoading(true);
          try {
            const response = await GetPaymentNotice({
              contracts: ids?.join(","),
            });

            if (response?.entry) {
              setPaymentResponse(response.entry);
            } else {
              setPaymentResponse([]);
            }
          } catch (error) {
            console.error("Error fetching payment notice:", error);
            setPaymentResponse([]);
          } finally {
            setLoading(false);
          }
        };
        detailsPlan();
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [currentContract]);

  if (loading)
    return <Skeleton width="100%" height="200px" sx={{ marginTop: -2 }} />;

  return (
    <>
      <Grid container boxShadow="0 0 5px lightgrey" p={2} mb={2}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            variant="subtitle2"
            fontWeight={500}
            color="neutral700.main"
            mb={2}
          >
            Pagamentos realizados
          </Typography>
        </Grid>
        {rowsPayment?.length ? (
          <Grid item xs={12}>
            <GenericTable
              tableColumns={columnsPayment}
              tableRows={rowsPayment}
            />
          </Grid>
        ) : (
          <NoDataWarning message="Nenhum pagamento identificado até o momento." />
        )}
      </Grid>

      {/* {rowsPayment?.filter((el: any) => el?.resource?.status !== "active")
        ?.length ? (
        <Grid container boxShadow="0 0 5px lightgrey" p={2} mb={2}>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="subtitle2"
              fontWeight={500}
              color="neutral700.main"
              mb={2}
            >
              Pagamentos de contratos cancelados
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <GenericTable
              tableColumns={columnsPayment}
              tableRows={rowsPayment?.filter(
                (el: any) => el?.resource?.status !== "active"
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )} */}
    </>
  );
}
